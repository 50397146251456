.frame {
  background-color: #00000033;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.frame .frame-wrapper {
  background-color: #00000033;
  height: 1030px;
  overflow: hidden;
  width: 1411px;
}

.frame .overlap-group-wrapper {
  height: 1030px;
  left: 5404px;
  position: relative;
  top: -484px;
}

.frame .overlap-group {
  border-radius: 20px;
  height: 749px;
  left: 408px;
  position: relative;
  top: 138px;
  width: 616px;
}

.frame .first-impression-add {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 749px;
  left: 0;
  overflow: hidden;
  padding: 32px 24px;
  position: absolute;
  top: 0;
  width: 616px;
}

.frame .title-icon {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 552px;
}

.frame .text-wrapper-2 {
  color: var(--typography-800);
  font-family: "Inter", Helvetica;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .icon {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.frame .upload {
  align-items: center;
  border: 1px dashed;
  border-color: var(--typography-200);
  border-radius: 12px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  padding: 40px 24px;
  position: relative;
  width: 552px;
}

.frame .title-icon-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.frame .paper-upload {
  height: 32px;
  position: relative;
  width: 32px;
}

.frame .title-subtitle {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.frame .p {
  color: var(--typography-950);
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.frame .text-wrapper-3 {
  color: var(--typography-600);
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.frame .line {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.frame .vector {
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 257px;
}

.frame .text-wrapper-4 {
  color: var(--typography-500);
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.24px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.frame .title {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.frame .import {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.frame .text-wrapper-5 {
  color: var(--typography-950);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .text {
  border: 1px solid;
  border-color: var(--typography-100);
  border-radius: 12px;
  flex: 0 0 auto;
  position: relative;
  width: 550px;
}

.frame .text-2 {
  border: 1px solid;
  border-color: var(--typography-100);
  border-radius: 12px;
  flex: 0 0 auto;
  position: relative;
  width: 548px;
}

.frame .text-3 {
  border: 1px solid;
  border-color: var(--typography-100);
  border-radius: 12px;
  flex: 0 0 auto;
  position: relative;
  width: 555px;
}

.frame .photo-wrapper {
  border: 1px solid;
  border-color: var(--typography-100);
  border-radius: 12px;
  flex: 0 0 auto;
  position: relative;
  width: 554px;
}

.frame .photo-instance {
  height: 24px !important;
  left: 517px !important;
  position: absolute !important;
  top: 12px !important;
  width: 24px !important;
}

.frame .btn {
  align-items: flex-start;
  display: inline-flex;
  gap: 8px;
  left: 380px;
  position: absolute;
  top: 694px;
}

.frame .div-wrapper {
  all: unset;
  align-items: center;
  border: 1px solid;
  border-color: var(--typography-100);
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 10px 24px;
  position: relative;
}

.frame .text-wrapper-6 {
  color: var(--typography-500);
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .btn-2 {
  all: unset;
  align-items: center;
  background-color: var(--typography-300);
  border-radius: 8px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 10px 24px;
  position: relative;
}

.frame .text-wrapper-7 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
