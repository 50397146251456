.form-log-in {
  align-items: flex-start;
  background-color: var(--color-background-default-default);
  border: 1px solid;
  border-color: var(--color-border-default-default);
  border-radius: var(--size-radius-200);
  display: inline-flex;
  flex-direction: column;
  gap: var(--size-space-600);
  min-width: 320px;
  padding: var(--size-space-600);
  position: relative;
}

.form-log-in .design-component-instance-node {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.form-log-in .button-group-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}
