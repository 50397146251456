.input-field {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: var(--size-space-200);
  position: relative;
}

.input-field .label {
  align-self: stretch;
  font-family: var(--body-base-font-family);
  font-size: var(--body-base-font-size);
  font-style: var(--body-base-font-style);
  font-weight: var(--body-base-font-weight);
  letter-spacing: var(--body-base-letter-spacing);
  line-height: var(--body-base-line-height);
  margin-top: -1px;
  position: relative;
}

.input-field .input {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-radius: var(--size-radius-200);
  display: flex;
  flex: 0 0 auto;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  min-width: 240px;
  overflow: hidden;
  padding: var(--size-space-300) var(--size-space-400) var(--size-space-300) var(--size-space-400);
  position: relative;
  width: 100%;
}

.input-field .value {
  flex: 1;
  font-family: var(--single-line-body-base-font-family);
  font-size: var(--single-line-body-base-font-size);
  font-style: var(--single-line-body-base-font-style);
  font-weight: var(--single-line-body-base-font-weight);
  letter-spacing: var(--single-line-body-base-letter-spacing);
  line-height: var(--single-line-body-base-line-height);
  margin-top: -0.5px;
  position: relative;
}

.input-field .disabled {
  color: var(--color-text-disabled-default);
}

.input-field .error {
  color: var(--color-text-default-default);
}

.input-field .default {
  color: var(--color-text-default-default);
}

.input-field .state-disabled {
  background-color: var(--color-background-disabled-default);
  border-color: var(--color-border-disabled-secondary);
}

.input-field .state-error {
  background-color: var(--color-background-default-default);
  border-color: var(--color-border-danger-default);
}

.input-field .state-default {
  background-color: var(--color-background-default-default);
  border-color: var(--color-border-default-default);
}

.input-field .state-0-disabled {
  color: var(--color-text-disabled-on-disabled);
}

.input-field .value-type-placeholder.state-0-error {
  color: var(--color-text-default-tertiary);
}

.input-field .value-type-default.state-0-error {
  color: var(--color-text-default-default);
}

.input-field .value-type-default.state-0-default {
  color: var(--color-text-default-default);
}

.input-field .value-type-placeholder.state-0-default {
  color: var(--color-text-default-tertiary);
}
