:root {
    --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
      "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
      "Source Han Sans CN", sans-serif;
  }
  
  .upload-main-container {
    overflow: hidden;
  }
  
  .upload-main-container,
  .upload-main-container * {
    box-sizing: border-box;
  }
  
  input,
  select,
  textarea,
  button {
    outline: 0;
  }
  
  .upload-main-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 20px;
    position: relative;
    width: 600px;
    margin: 0 auto;
    padding: 32px 24px 32px 24px;
    background: #ffffff;
    overflow: hidden;
    border-radius: 20px;
  }
  .upload-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    width: 552px;
  }
  .upload-text {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 24px;
    color: #3a3f52;
    font-family: Inter, var(--default-font-family);
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    white-space: nowrap;
    z-index: 1;
  }
  .upload-wrapper {
    flex-shrink: 0;
    position: relative;
    width: 24px;
    height: 24px;
    background: #ecedf2;
    z-index: 2;
    overflow: hidden;
    border-radius: 30px;
  }
  .upload-img {
    position: relative;
    width: 7.5px;
    height: 7.5px;
    margin: 8.25px 0 0 8.25px;
    background: url(../../icons/cdb7eca4-5663-4739-8a21-027500c8fcf9.png)
      no-repeat center;
    background-size: cover;
    z-index: 3;
  }
  .upload-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 552px;
    padding: 40px 24px 40px 24px;
    border: 1px dashed #d5d8e2;
    z-index: 4;
    overflow: hidden;
    border-radius: 12px;
  }
  .upload-title-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 16px;
    position: relative;
    min-width: 0;
    z-index: 5;
  }
  .upload-paper-upload {
    flex-shrink: 0;
    position: relative;
    width: 32px;
    height: 32px;
    z-index: 6;
    overflow: hidden;
  }
  .upload-iconly-outline-paper {
    position: relative;
    width: 22.735px;
    height: 26.487px;
    margin: 2.666px 0 0 4.004px;
    z-index: 7;
  }
  .upload-paper-upload-1 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 8;
  }
  .upload-combined-shape {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(../../icons/8f6f6cd3-a036-44c5-b1a2-b5c579d0c03c.png)
      no-repeat center;
    background-size: 100% 100%;
    z-index: 9;
  }
  .upload-title-subtitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    width: 204px;
    z-index: 10;
  }
  .upload-click-select-audio {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 17px;
    color: #282a37;
    font-family: Inter, var(--default-font-family);
    font-size: 14px;
    font-weight: 600;
    line-height: 16.943px;
    text-align: left;
    white-space: nowrap;
    z-index: 11;
  }
  .upload-mp3-files-only {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 17px;
    color: #515978;
    font-family: Inter, var(--default-font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 16.943px;
    text-align: left;
    white-space: nowrap;
    z-index: 12;
  }
  .upload-line {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 552px;
    z-index: 13;
  }
  .upload-vector {
    flex-shrink: 0;
    position: relative;
    width: 257px;
    height: 1px;
    background: url(../../icons/eeb6d121-3f9d-41ba-8ca7-ca1e2c8056f7.png)
      no-repeat center;
    background-size: cover;
    z-index: 14;
  }
  .upload-or {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 15px;
    color: #667091;
    font-family: Inter, var(--default-font-family);
    font-size: 12px;
    font-weight: 400;
    line-height: 14.523px;
    text-align: left;
    white-space: nowrap;
    letter-spacing: 0.24px;
    z-index: 15;
  }
  .upload-vector-2 {
    flex-shrink: 0;
    position: relative;
    width: 257px;
    height: 1px;
    background: url(../../icons/c733f1e0-47fd-44a4-ad31-8db8cbc16318.png)
      no-repeat center;
    background-size: cover;
    z-index: 16;
  }
  .upload-wrapper-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    width: 536px;
    z-index: 17;
  }
  .upload-text-5 {
    align-self: stretch;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    min-width: 0;
    height: 22px;
    color: #1e1e1e;
    font-family: Inter, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    white-space: nowrap;
    z-index: 18;
  }
  .upload-group-3 {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: nowrap;
    flex-shrink: 0;
    position: relative;
    min-width: 240px;
    padding: 12px 16px 12px 16px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    z-index: 19;
    overflow: hidden;
    border-radius: 8px;
  }
  .upload-text-6 {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    height: 6px;
    color: #1e1e1e;
    font-family: Inter, var(--default-font-family);
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    z-index: 20;
  }
  .upload-section-6 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 24px;
    position: relative;
    width: 552px;
    left: 430px;
    z-index: 21;
  }
  .upload-group-4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-shrink: 0;
  }
  .upload-no-resize {
    resize: none; /* Bloqueia o redimensionamento */
    overflow: hidden; /* Oculta o retângulo de redimensionamento */
    width: 100%; /* Define a largura desejada */
    height: 16px; /* Define a altura desejada */
    border: none; /* Remove a borda */
    outline: none;
  }
  .upload-btn {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 8px;
    position: relative;
    width: 97px;
    cursor: pointer;
    background: transparent;
    border: none;
    z-index: 23;
  }
  .upload-btn-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    width: 97px;
    padding: 10px 24px 10px 24px;
    background: #b0b5c9;
    z-index: 24;
    border-radius: 8px;
  }
  
  .upload-btn-4 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 10px;
    position: relative;
    top: 5px;
    width: 140px;
    padding: 10px 24px 10px 24px;
    background: #E7F5FE;
    z-index: 24;
    border-radius: 8px;
    border: none; /* Remove a borda preta */
    color: #18a0fb;
  }