.button {
  all: unset;
  align-items: center;
  border-radius: var(--size-radius-200);
  box-sizing: border-box;
  display: inline-flex;
  gap: var(--size-space-200);
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.button .text-wrapper {
  font-family: var(--single-line-body-base-font-family);
  font-size: var(--single-line-body-base-font-size);
  font-style: var(--single-line-body-base-font-style);
  font-weight: var(--single-line-body-base-font-weight);
  letter-spacing: var(--single-line-body-base-letter-spacing);
  line-height: var(--single-line-body-base-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.button.state-1-disabled {
  background-color: var(--color-background-disabled-default);
  border: 1px solid;
  border-color: var(--color-border-disabled-default);
}

.button.state-1-hover {
  border: 1px solid;
}

.button.small {
  padding: var(--size-space-200);
}

.button.medium {
  padding: var(--size-space-300);
}

.button.primary {
  border: 1px solid;
}

.button.neutral {
  border: 1px solid;
}

.button.state-1-hover.subtle {
  border-color: var(--color-border-default-default);
}

.button.neutral.state-1-default {
  background-color: var(--color-background-neutral-tertiary);
  border-color: var(--color-border-neutral-secondary);
}

.button.neutral.state-1-hover {
  background-color: var(--color-background-neutral-tertiary-hover);
  border-color: var(--color-border-neutral-secondary);
}

.button.state-1-default.primary {
  background-color: var(--color-background-brand-default);
  border-color: var(--color-border-brand-default);
}

.button.state-1-hover.primary {
  background-color: var(--color-background-brand-hover);
  border-color: var(--color-border-brand-default);
}

.button.state-1-disabled .text-wrapper {
  color: var(--color-text-disabled-on-disabled);
}

.button.state-1-hover.subtle .text-wrapper {
  color: var(--color-text-default-default);
}

.button.neutral.state-1-default .text-wrapper {
  color: var(--color-text-default-default);
}

.button.neutral.state-1-hover .text-wrapper {
  color: var(--color-text-default-default);
}

.button.state-1-default.primary .text-wrapper {
  color: var(--color-text-brand-on-brand);
}

.button.state-1-hover.primary .text-wrapper {
  color: var(--color-text-brand-on-brand);
}

.button.state-1-default.small.subtle .text-wrapper {
  color: var(--color-text-default-default);
}

.button.state-1-default.subtle.medium .text-wrapper {
  color: var(--color-text-neutral-default);
}
