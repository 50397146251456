.index {
  background-color: #121316;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.index .div-2 {
  background-color: var(--dark);
  width: 1440px;
  height: 5644px;
  position: relative;
  overflow: hidden;
}

.index .footer {
  background-color: #0000;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 50px;
  width: 1440px;
  height: 426px;
  padding: 0 80px;
  display: flex;
  position: absolute;
  top: 5321px;
  left: -41px;
}

.index .frame-2 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 25px;
  display: inline-flex;
  position: relative;
}

.index .logo {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.index .adaptive-audio-mic {
  width: 40px;
  height: 40px;
  position: relative;
}

.index .text-wrapper-8 {
  color: var(--light);
  letter-spacing: 0;
  width: fit-content;
  margin-top: -1px;
  font-family: Poppins, Helvetica;
  font-size: 28px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.index .element-linearity-gmb {
  color: var(--light);
  letter-spacing: 0;
  opacity: .5;
  text-align: center;
  width: fit-content;
  font-family: Poppins, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.index .all-inclusive {
  background-color: var(--light);
  opacity: .62;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 54px;
  padding: 15px;
  display: inline-flex;
  position: absolute;
  top: 5262px;
  left: 1372px;
  overflow-y: scroll;
  box-shadow: 4px 4px 4px #00000026;
}

.index .arrow-up {
  width: 24px !important;
  height: 34px !important;
  position: relative !important;
}

.index .connection {
  width: 1049px;
  height: 629px;
  position: absolute;
  top: 4602px;
  left: 195px;
}

.index .text-4 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 778px;
  padding: 0 30px;
  display: flex;
  position: absolute;
  top: 0;
  left: 133px;
}

.audio-player-overlay {
  z-index: 1000;
  background-color: #fff;
  border-radius: 5px;
  width: 1500px;
  padding: 10px;
  position: fixed;
  bottom: 30px;
  right: 200px;
  box-shadow: 0 0 10px #00000080;
}

.index .text-wrapper-9 {
  color: var(--light);
  letter-spacing: 0;
  width: fit-content;
  margin-top: 20px;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-99 {
  color: var(--light);
  letter-spacing: 0;
  width: fit-content;
  margin-top: -1px;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: relative;
  transform: rotate(180deg);
}

.index .group {
  width: 1049px;
  height: 438px;
  position: absolute;
  top: 191px;
  left: 0;
}

.index .logo-2 {
  background-color: var(--primary);
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 216px;
  height: 220px;
  padding: 30px;
  display: flex;
  position: absolute;
  top: 48px;
  left: 390px;
}

.index .img {
  width: 75px;
  height: 75px;
  margin-top: -5.5px;
  position: relative;
}

.index .text-wrapper-10 {
  color: var(--light);
  letter-spacing: 0;
  text-align: center;
  width: 203px;
  height: 86px;
  margin-bottom: -4.5px;
  margin-left: -23.5px;
  margin-right: -23.5px;
  font-family: Poppins, Helvetica;
  font-size: 28px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.index .all-inclusive-2 {
  width: 54px;
  height: 54px;
  position: absolute;
  top: 13px;
  left: 133px;
}

.index .circles-wrapper {
  background-color: var(--primary);
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  height: 80px;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 0;
  left: 888px;
}

.index .img-2 {
  width: 35px;
  height: 35px;
  position: relative;
}

.index .contactless-wrapper {
  background-color: var(--primary);
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80px;
  height: 80px;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 149px;
  left: 197px;
}

.index .img-wrapper {
  background-color: var(--primary);
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 52px;
  height: 52px;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 110px;
  left: 766px;
}

.index .img-3 {
  width: 20px;
  height: 20px;
  position: relative;
}

.index .all-inclusive-3 {
  width: 62px;
  height: 62px;
  position: absolute;
  top: 268px;
  left: 849px;
}

.index .all-inclusive-4 {
  width: 62px;
  height: 62px;
  position: absolute;
  top: 301px;
  left: 262px;
}

.index .editor-choice-wrapper {
  background-color: var(--dark-secondary);
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 83px;
  height: 83px;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 355px;
  left: 699px;
  box-shadow: 4px 4px 4px #00000026;
}

.index .all-inclusive-5 {
  background-color: var(--dark-secondary);
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 83px;
  height: 83px;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 154px;
  left: 966px;
  box-shadow: 4px 4px 4px #00000026;
}

.index .all-inclusive-6 {
  background-color: var(--dark-secondary);
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 58px;
  height: 58px;
  padding: 15px;
  display: flex;
  position: absolute;
  top: 272px;
  left: 0;
  box-shadow: 4px 4px 4px #00000026;
}

.index .editor-choice {
  width: 25px;
  height: 25px;
  position: relative;
}

.index .host {
  width: 1025px;
  height: 982px;
  position: absolute;
  top: 3393px;
  left: 85px;
  transform: rotate(180deg);
}

.index .text-5 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 777.75px;
  padding: 0 30px;
  display: flex;
  position: absolute;
  top: 798px;
  left: 0;
}

.index .frame-3 {
  background-color: var(--primary);
  border-radius: 100px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 30px;
  display: inline-flex;
  position: relative;
  transform: rotate(180deg);
}

.index .text-wrapper-11 {
  color: var(--light);
  letter-spacing: 0;
  text-align: center;
  width: fit-content;
  margin-top: -1px;
  font-family: Poppins, Helvetica;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.index .card {
  background-color: var(--dark-secondary);
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 1000px;
  padding: 15px;
  display: inline-flex;
  position: absolute;
  left: auto;
  right: 100px;
  box-shadow: 5px 7px 17px #00000012;
}

.index .image {
  object-fit: cover;
  width: 354px;
  height: 340px;
  position: relative;
  transform: rotate(-180deg);
}

.index .frame-4 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px 0;
  display: inline-flex;
  position: relative;
  transform: rotate(180deg);
}

.index .text-wrapper-12 {
  color: var(--light);
  letter-spacing: 0;
  text-align: center;
  width: 347px;
  font-family: Poppins, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-13 {
  color: var(--light);
  letter-spacing: .32px;
  text-align: center;
  width: auto;
  margin-top: -1px;
  font-family: Open Sans, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  position: relative;
}

.index .frame-5 {
  background-color: var(--primary);
  border-radius: 100px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px 30px;
  display: inline-flex;
  position: relative;
  transform: rotate(180deg);
}

.index .text-wrapper-14 {
  color: var(--light);
  letter-spacing: 0;
  text-align: center;
  width: fit-content;
  margin-top: -1px;
  font-family: Poppins, Helvetica;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.index .lates-episodes {
  width: 1282px;
  height: 1007px;
  position: absolute;
  top: 2054px;
  left: 80px;
}

.index .frame-6 {
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: absolute;
  top: 248px;
  left: 0;
}

.index .frame-7 {
  flex-direction: column;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  margin-bottom: 30px;
  display: inline-flex;
}

.scrollable-container {
  max-height: 1400px;
  overflow: hidden scroll;
}

.index .card-2 {
  background-color: var(--dark-secondary);
  border-radius: 20px;
  flex: none;
  align-items: flex-start;
  gap: 43px;
  width: 1000px;
  padding: 30px 40px;
  display: inline-flex;
  position: relative;
  box-shadow: 4px 7px 17px #00000012;
}

.index .img-4 {
  flex: none;
  position: relative;
}

.index .text-6 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.index .text-wrapper-15 {
  color: var(--light);
  letter-spacing: 0;
  width: fit-content;
  margin-top: -1px;
  font-family: Poppins, Helvetica;
  font-size: 28px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.index .card-body {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 153px;
  display: inline-flex;
  position: relative;
}

.index .frame-8 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.index .frame-9 {
  flex: none;
  width: 303px;
  position: relative;
}

.index .frame-10 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.index .text-wrapper-16 {
  color: var(--light);
  letter-spacing: 0;
  width: fit-content;
  margin-top: -1px;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.index .play-arrow {
  width: 60px;
  height: 60px;
  display: block;
  position: relative;
  right: -120px;
}

.index .frame-11 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 41px;
  display: inline-flex;
  position: relative;
}

.index .frame-12 {
  flex-wrap: wrap;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  width: 400px;
  display: flex;
  position: relative;
  overflow-x: scroll;
}

.index .frame-13 {
  background-color: var(--dark-secondary);
  border-radius: 20px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 21px;
  padding: 20px;
  display: inline-flex;
  position: relative;
  box-shadow: 2px 4px 17px #0000000d;
}

.index .images {
  background-color: #c4c4c4;
  border-radius: 15px;
  width: 144px;
  height: 144px;
  position: relative;
}

.index .group-2 {
  width: 148px;
  height: 71px;
  margin-right: -4px;
  position: relative;
}

.index .text-wrapper-17 {
  color: var(--light);
  letter-spacing: .32px;
  width: 144px;
  font-family: Open Sans, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  top: 39px;
  left: 0;
}

.index .text-wrapper-18 {
  color: var(--light);
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.index .frame-14 {
  background-color: var(--primary);
  border-radius: 100px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 30px;
  display: inline-flex;
  position: relative;
}

.index .text-wrapper-19 {
  color: var(--light);
  letter-spacing: 0;
  width: fit-content;
  margin-top: -1px;
  font-family: Poppins, Helvetica;
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.index .frame-15 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 777.75px;
  padding: 0 30px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.index .frame-16 {
  background-color: var(--primary);
  border-radius: 100px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 30px;
  display: inline-flex;
  position: relative;
}

.index .text-wrapper-20 {
  color: var(--light);
  letter-spacing: 0;
  width: 607px;
  font-family: Poppins, Helvetica;
  font-size: 42px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.index .about-us {
  width: 1207px;
  height: 529px;
  position: absolute;
  top: 1193px;
  left: 116px;
}

.index .images-2 {
  width: 464px;
  height: 529px;
  position: absolute;
  top: 0;
  left: 0;
}

.index .overlap-group-2 {
  height: 529px;
  position: relative;
}

.index .images-3 {
  background-color: #c4c4c4;
  border-radius: 20px;
  width: 341px;
  height: 426px;
  position: absolute;
  top: 0;
  left: 0;
}

.index .images-4 {
  border: 10px solid;
  border-color: var(--dark);
  background-color: #c4c4c4;
  border-radius: 20px;
  width: 277px;
  height: 333px;
  position: absolute;
  top: 196px;
  left: 187px;
}

.index .frame-17 {
  background-color: var(--dark-secondary);
  border-radius: 15px;
  align-items: flex-start;
  gap: 26px;
  padding: 25px;
  display: inline-flex;
  position: absolute;
  top: 363px;
  left: 58px;
  box-shadow: 4px 7px 10px #00000012;
}

.index .frame-18 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 3px;
  display: inline-flex;
  position: relative;
}

.index .text-wrapper-21 {
  color: var(--light);
  letter-spacing: 0;
  width: fit-content;
  margin-top: -1px;
  font-family: Poppins, Helvetica;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-22 {
  color: var(--light-secondary);
  letter-spacing: 0;
  width: fit-content;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.index .text-7 {
  width: 611px;
  height: 509px;
  position: absolute;
  top: 0;
  left: 600px;
}

.index .text-wrapper-23 {
  color: var(--light);
  letter-spacing: 0;
  width: 607px;
  font-family: Poppins, Helvetica;
  font-size: 42px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 58px;
  left: 0;
}

.index .frame-19 {
  background-color: var(--primary);
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 30px;
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 0;
}

.index .text-wrapper-24 {
  color: var(--light);
  letter-spacing: 0;
  width: fit-content;
  margin-top: -1px;
  font-family: Poppins, Helvetica;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-25 {
  color: var(--light);
  letter-spacing: .32px;
  width: 584px;
  font-family: Open Sans, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  top: 221px;
  left: 5px;
}

.index .overlap {
  width: 1669px;
  height: 1444px;
  position: absolute;
  top: -419px;
  left: 80px;
}

.index .header {
  background-color: #0000;
  width: 1669px;
  height: 1444px;
  position: absolute;
  top: 0;
  left: 0;
}

.index .overlap-2 {
  width: 1667px;
  height: 1212px;
  position: absolute;
  top: 0;
  left: 0;
}

.index .ellipse {
  background-color: var(--primary);
  border-radius: 464px;
  width: 928px;
  height: 928px;
  position: absolute;
  top: 0;
  left: 739px;
}

.index .images-5 {
  background-color: #060f16;
  border-radius: 0 0 40px 40px;
  width: 476px;
  height: 748px;
  position: absolute;
  top: 419px;
  left: 804px;
}

.index .text-8 {
  flex-direction: column;
  align-items: flex-start;
  gap: 42px;
  display: inline-flex;
  position: absolute;
  top: 634px;
  left: 0;
}

.index .group-3 {
  width: 751px;
  height: 252px;
  margin-right: -2px;
  position: relative;
}

.index .overlap-group-3 {
  width: 749px;
  height: 252px;
  position: relative;
}

.index .rectangle {
  background-color: var(--primary);
  width: 347px;
  height: 81px;
  position: absolute;
  top: 171px;
  left: 0;
  box-shadow: 0 4px 4px #00000040;
}

.index .text-wrapper-26 {
  color: var(--light);
  letter-spacing: 0;
  width: 739px;
  font-family: Poppins, Helvetica;
  font-size: 56px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 10px;
}

.index .text-wrapper-27 {
  color: var(--light);
  letter-spacing: .32px;
  width: 643px;
  font-family: Open Sans, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  position: relative;
}

.index .vector-2 {
  width: 372px;
  height: 124px;
  position: absolute;
  top: 1088px;
  left: 408px;
}

.index .footballer {
  width: 475px;
  height: 748px;
  position: absolute;
  top: 419px;
  left: 804px;
}

.index .frame-20 {
  background-color: var(--dark-secondary);
  border-radius: 15px;
  align-items: flex-start;
  gap: 26px;
  padding: 25px;
  display: inline-flex;
  position: absolute;
  top: 1023px;
  left: 578px;
  box-shadow: 4px 7px 10px #00000012;
}

.index .frame-21 {
  width: 203px;
  height: 63px;
  display: block;
  position: absolute;
  top: 1104px;
  left: 0;
}

.index .text-wrapper-28 {
  color: var(--light);
  letter-spacing: 0;
  opacity: .8;
  text-align: center;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.index .text-9 {
  width: 432px;
  height: 88px;
  position: absolute;
  top: 1238px;
  left: 351px;
}

.index .text-wrapper-29 {
  color: var(--light);
  letter-spacing: .32px;
  width: 428px;
  font-family: Open Sans, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  position: absolute;
  top: 56px;
  left: 0;
}

.index .text-10 {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: absolute;
  top: 1238px;
  left: 0;
}

.index .text-wrapper-30 {
  color: var(--primary);
  letter-spacing: 0;
  width: fit-content;
  margin-top: -1px;
  font-family: Poppins, Helvetica;
  font-size: 42px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-31 {
  color: var(--light-secondary);
  letter-spacing: 0;
  width: fit-content;
  font-family: Poppins, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.index .theme {
  width: 460px;
  height: 206px;
  position: absolute;
  top: 1238px;
  left: 813px;
}

.index .frame-22 {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 15px 20px;
  width: 458px;
  display: flex;
  position: absolute;
  top: 56px;
  left: 0;
}

.index .frame-23 {
  background-color: var(--dark-secondary);
  border-radius: 100px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 20px;
  display: inline-flex;
  position: relative;
  box-shadow: 4px 4px 4px #00000012;
}

.index .menu {
  align-items: center;
  gap: 96px;
  display: inline-flex;
  position: absolute;
  top: 483px;
  left: 0;
}

.index .navbar {
  flex: none;
  align-items: flex-start;
  gap: 35px;
  display: inline-flex;
  position: relative;
}

.index .text-wrapper-32 {
  color: var(--light);
  letter-spacing: 0;
  opacity: .8;
  text-align: center;
  width: fit-content;
  margin-top: -1px;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: relative;
}

.index .text-wrapper-33 {
  color: var(--light);
  letter-spacing: 0;
  opacity: .8;
  text-align: center;
  width: fit-content;
  margin-top: -1px;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  display: block;
  position: relative;
}

.frame {
  background-color: #0003;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.frame .frame-wrapper {
  background-color: #0003;
  width: 1411px;
  height: 1030px;
  overflow: hidden;
}

.frame .overlap-group-wrapper {
  height: 1030px;
  position: relative;
  top: -484px;
  left: 5404px;
}

.frame .overlap-group {
  border-radius: 20px;
  width: 616px;
  height: 749px;
  position: relative;
  top: 138px;
  left: 408px;
}

.frame .first-impression-add {
  background-color: #fff;
  border-radius: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 616px;
  height: 749px;
  padding: 32px 24px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.frame .title-icon {
  flex: none;
  justify-content: space-between;
  align-items: center;
  width: 552px;
  display: flex;
  position: relative;
}

.frame .text-wrapper-2 {
  color: var(--typography-800);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .icon {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.frame .upload {
  border: 1px dashed;
  border-color: var(--typography-200);
  border-radius: 12px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 552px;
  padding: 40px 24px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .title-icon-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.frame .paper-upload {
  width: 32px;
  height: 32px;
  position: relative;
}

.frame .title-subtitle {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.frame .p {
  color: var(--typography-950);
  letter-spacing: 0;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .text-wrapper-3 {
  color: var(--typography-600);
  letter-spacing: 0;
  width: fit-content;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame .line {
  flex: none;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.frame .vector {
  object-fit: cover;
  width: 257px;
  height: 1px;
  position: relative;
}

.frame .text-wrapper-4 {
  color: var(--typography-500);
  letter-spacing: .24px;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame .title {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.frame .import {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-5 {
  color: var(--typography-950);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  position: relative;
}

.frame .text {
  border: 1px solid;
  border-color: var(--typography-100);
  border-radius: 12px;
  flex: none;
  width: 550px;
  position: relative;
}

.frame .text-2 {
  border: 1px solid;
  border-color: var(--typography-100);
  border-radius: 12px;
  flex: none;
  width: 548px;
  position: relative;
}

.frame .text-3 {
  border: 1px solid;
  border-color: var(--typography-100);
  border-radius: 12px;
  flex: none;
  width: 555px;
  position: relative;
}

.frame .photo-wrapper {
  border: 1px solid;
  border-color: var(--typography-100);
  border-radius: 12px;
  flex: none;
  width: 554px;
  position: relative;
}

.frame .photo-instance {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 12px !important;
  left: 517px !important;
}

.frame .btn {
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: absolute;
  top: 694px;
  left: 380px;
}

.frame .div-wrapper {
  all: unset;
  border: 1px solid;
  border-color: var(--typography-100);
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 24px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-6 {
  color: var(--typography-500);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: relative;
}

.frame .btn-2 {
  all: unset;
  background-color: var(--typography-300);
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 24px;
  display: inline-flex;
  position: relative;
}

.frame .text-wrapper-7 {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  position: relative;
}

.vuesax-linear-minus {
  opacity: .5;
  background-image: url("vuesax-linear-minus-square.b18a2a55.svg");
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
}

.playlists {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.playlists .div-3 {
  background-color: #fff;
  width: 1440px;
  height: 1024px;
  position: relative;
  overflow: hidden;
}

.playlists .vuesax-bold-gallery-wrapper {
  background-color: #d9d9d9;
  border-radius: 10px;
  width: 52px;
  height: 52px;
  position: absolute;
  top: 42px;
  left: 1336px;
}

.playlists .icon-instance-node {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 14px !important;
  left: 14px !important;
}

.playlists .group-wrapper {
  width: 224px;
  height: 72px;
  position: absolute;
  top: 32px;
  left: 156px;
}

.playlists .group-4 {
  width: 228px;
  height: 72px;
  position: relative;
}

.playlists .text-wrapper-34 {
  color: #3e435d;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.playlists .text-wrapper-35 {
  color: #ada7a7;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 48px;
  left: 0;
}

.playlists .group-5 {
  width: 356px;
  height: 52px;
  position: absolute;
  top: 42px;
  left: 964px;
}

.playlists .overlap-group-4 {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  width: 354px;
  height: 52px;
  position: relative;
}

.playlists .vuesax-linear-search {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 13px !important;
  left: 19px !important;
}

.playlists .text-wrapper-36 {
  color: #ada7a7;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 13px;
  left: 59px;
}

.playlists .vector-3 {
  width: 1px;
  height: 1024px;
  position: absolute;
  top: 0;
  left: 116px;
}

.playlists .group-6 {
  width: 54px;
  height: 784px;
  position: absolute;
  top: 208px;
  left: 32px;
}

.playlists .vuesax-bold-logout-wrapper {
  background-color: #18a0fb;
  border-radius: 8px;
  width: 52px;
  height: 52px;
  display: block;
  position: absolute;
  top: 732px;
  left: 0;
}

.playlists .text-wrapper-37 {
  color: #000;
  letter-spacing: 0;
  opacity: .3;
  font-family: DM Sans, Helvetica;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 8px;
}

.playlists .vuesax-bold-element {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 38px !important;
  left: 14px !important;
}

.playlists .vuesax-bold-discover {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 94px !important;
  left: 14px !important;
}

.playlists .overlap-wrapper {
  width: 154px;
  height: 52px;
  display: block;
  position: absolute;
  top: 136px;
  left: 1210px;
}

.playlists .overlap-3 {
  border-radius: 8px;
  width: 152px;
  height: 52px;
  position: relative;
}

.playlists .rectangle-2 {
  opacity: .1;
  background-color: #18a0fb;
  border-radius: 8px;
  width: 152px;
  height: 52px;
  position: absolute;
  top: 0;
  left: 0;
}

.playlists .text-wrapper-38 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 20px;
}

.playlists .group-7 {
  width: 196px;
  height: 52px;
  position: absolute;
  top: 136px;
  left: 999px;
}

.playlists .overlap-4 {
  border-radius: 8px;
  width: 194px;
  height: 52px;
  position: relative;
}

.playlists .rectangle-3 {
  opacity: .1;
  background-color: red;
  border-radius: 8px;
  width: 194px;
  height: 52px;
  position: absolute;
  top: 0;
  left: 0;
}

.playlists .text-wrapper-39 {
  color: #fb1818;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 12px;
}

.playlists .text-wrapper-40 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 147px;
  left: 156px;
}

.playlists .overlap-5 {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  width: 1244px;
  height: 835px;
  position: absolute;
  top: 220px;
  left: 156px;
}

.playlists .vuesax-linear-minus-square {
  background-image: url("vuesax-linear-minus-square-2.e4eeb1b9.svg") !important;
  position: absolute !important;
  top: 23px !important;
  left: 23px !important;
}

.playlists .vuesax-linear-minus-instance {
  background-image: url("vuesax-linear-minus-square-2.e4eeb1b9.svg") !important;
  position: absolute !important;
  top: 114px !important;
  left: 23px !important;
}

.playlists .vuesax-linear-minus-square-instance {
  background-image: url("vuesax-linear-minus-square-2.e4eeb1b9.svg") !important;
  position: absolute !important;
  top: 334px !important;
  left: 23px !important;
}

.playlists .vuesax-linear-minus-2 {
  background-image: url("vuesax-linear-minus-square-2.e4eeb1b9.svg") !important;
  position: absolute !important;
  top: 444px !important;
  left: 23px !important;
}

.playlists .vuesax-linear-minus-3 {
  background-image: url("vuesax-linear-minus-square-2.e4eeb1b9.svg") !important;
  position: absolute !important;
  top: 664px !important;
  left: 23px !important;
}

.playlists .vuesax-linear-minus-4 {
  background-image: url("vuesax-linear-minus-square-2.e4eeb1b9.svg") !important;
  position: absolute !important;
  top: 774px !important;
  left: 23px !important;
}

.playlists .text-wrapper-41 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 71px;
}

.playlists .text-wrapper-42 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 251px;
}

.playlists .text-wrapper-43 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 113px;
  left: 251px;
}

.playlists .text-wrapper-44 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 223px;
  left: 251px;
}

.playlists .text-wrapper-45 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 333px;
  left: 251px;
}

.playlists .text-wrapper-46 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 443px;
  left: 251px;
}

.playlists .text-wrapper-47 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 553px;
  left: 251px;
}

.playlists .text-wrapper-48 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 663px;
  left: 251px;
}

.playlists .text-wrapper-49 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 773px;
  left: 251px;
}

.playlists .text-wrapper-50 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 113px;
  left: 570px;
}

.playlists .text-wrapper-51 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 223px;
  left: 570px;
}

.playlists .text-wrapper-52 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 333px;
  left: 570px;
}

.playlists .text-wrapper-53 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 443px;
  left: 570px;
}

.playlists .text-wrapper-54 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 553px;
  left: 570px;
}

.playlists .text-wrapper-55 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 663px;
  left: 570px;
}

.playlists .text-wrapper-56 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 773px;
  left: 570px;
}

.playlists .text-wrapper-57 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 113px;
  left: 739px;
}

.playlists .text-wrapper-58 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 223px;
  left: 739px;
}

.playlists .text-wrapper-59 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 333px;
  left: 739px;
}

.playlists .text-wrapper-60 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 443px;
  left: 739px;
}

.playlists .text-wrapper-61 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 553px;
  left: 739px;
}

.playlists .text-wrapper-62 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 663px;
  left: 739px;
}

.playlists .text-wrapper-63 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 773px;
  left: 739px;
}

.playlists .text-wrapper-64 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 113px;
  left: 959px;
}

.playlists .text-wrapper-65 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 223px;
  left: 959px;
}

.playlists .text-wrapper-66 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 333px;
  left: 959px;
}

.playlists .text-wrapper-67 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 443px;
  left: 959px;
}

.playlists .text-wrapper-68 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 553px;
  left: 959px;
}

.playlists .text-wrapper-69 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 663px;
  left: 959px;
}

.playlists .text-wrapper-70 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 773px;
  left: 959px;
}

.playlists .text-wrapper-71 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 570px;
}

.playlists .text-wrapper-72 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 739px;
}

.playlists .text-wrapper-73 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 959px;
}

.playlists .vector-4 {
  object-fit: cover;
  width: 1196px;
  height: 1px;
  position: absolute;
  top: 70px;
  left: 23px;
}

.playlists .vector-5 {
  object-fit: cover;
  width: 1196px;
  height: 1px;
  position: absolute;
  top: 180px;
  left: 23px;
}

.playlists .vector-6 {
  object-fit: cover;
  width: 1196px;
  height: 1px;
  position: absolute;
  top: 290px;
  left: 23px;
}

.playlists .vector-7 {
  object-fit: cover;
  width: 1196px;
  height: 1px;
  position: absolute;
  top: 400px;
  left: 23px;
}

.playlists .vector-8 {
  object-fit: cover;
  width: 1196px;
  height: 1px;
  position: absolute;
  top: 510px;
  left: 23px;
}

.playlists .vector-9 {
  object-fit: cover;
  width: 1196px;
  height: 1px;
  position: absolute;
  top: 620px;
  left: 23px;
}

.playlists .vector-10 {
  object-fit: cover;
  width: 1196px;
  height: 1px;
  position: absolute;
  top: 730px;
  left: 23px;
}

.playlists .vuesax-bold-gallery-13-wrapper {
  background-color: #d9d9d9;
  border-radius: 6px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 92px;
  left: 72px;
}

.playlists .vuesax-bold-gallery {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 23px !important;
  left: 48px !important;
}

.playlists .vuesax-bold-gallery-12-wrapper {
  background-color: #d9d9d9;
  border-radius: 6px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 202px;
  left: 72px;
}

.playlists .overlap-6 {
  background-color: #d9d9d9;
  border-radius: 6px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 312px;
  left: 72px;
}

.playlists .overlap-7 {
  background-color: #d9d9d9;
  border-radius: 6px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 422px;
  left: 72px;
}

.playlists .overlap-8 {
  background-color: #d9d9d9;
  border-radius: 6px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 532px;
  left: 72px;
}

.playlists .overlap-9 {
  background-color: #d9d9d9;
  border-radius: 6px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 642px;
  left: 72px;
}

.playlists .overlap-10 {
  background-color: #d9d9d9;
  border-radius: 6px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 752px;
  left: 72px;
}

.playlists .vuesax-bold-link {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 114px !important;
  left: 1137px !important;
}

.playlists .vuesax-bold-link-square-1 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 224px !important;
  left: 1137px !important;
}

.playlists .vuesax-bold-link-square-1-instance {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 334px !important;
  left: 1137px !important;
}

.playlists .vuesax-bold-link-2 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 444px !important;
  left: 1137px !important;
}

.playlists .vuesax-bold-link-3 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 554px !important;
  left: 1137px !important;
}

.playlists .vuesax-bold-link-4 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 664px !important;
  left: 1137px !important;
}

.playlists .vuesax-bold-link-5 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 774px !important;
  left: 1137px !important;
}

.playlists .vuesax-bold-edit {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 114px !important;
  left: 1185px !important;
}

.playlists .vuesax-bold-edit-2-10 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 224px !important;
  left: 1185px !important;
}

.playlists .vuesax-bold-edit-2-8 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 334px !important;
  left: 1185px !important;
}

.playlists .vuesax-bold-edit-2-11 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 444px !important;
  left: 1185px !important;
}

.playlists .vuesax-bold-edit-2-13 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 554px !important;
  left: 1185px !important;
}

.playlists .vuesax-bold-edit-2-10-instance {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 664px !important;
  left: 1185px !important;
}

.playlists .vuesax-bold-edit-2-8-instance {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 774px !important;
  left: 1185px !important;
}

.playlists .vuesax-bold-tick {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 224px !important;
  left: 23px !important;
}

.playlists .vuesax-bold-tick-square-1 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 554px !important;
  left: 23px !important;
}

.playlists .overlap-11 {
  background-color: #d9d9d9;
  width: 66px;
  height: 61px;
  position: absolute;
  top: 37px;
  left: 33px;
}

.playlists .vuesax-bold-gallery-13 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 19px !important;
  left: 21px !important;
}

.button {
  all: unset;
  border-radius: var(--size-radius-200);
  box-sizing: border-box;
  align-items: center;
  gap: var(--size-space-200);
  justify-content: center;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.button .text-wrapper {
  font-family: var(--single-line-body-base-font-family);
  font-size: var(--single-line-body-base-font-size);
  font-style: var(--single-line-body-base-font-style);
  font-weight: var(--single-line-body-base-font-weight);
  letter-spacing: var(--single-line-body-base-letter-spacing);
  line-height: var(--single-line-body-base-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.button.state-1-disabled {
  background-color: var(--color-background-disabled-default);
  border: 1px solid;
  border-color: var(--color-border-disabled-default);
}

.button.state-1-hover {
  border: 1px solid;
}

.button.small {
  padding: var(--size-space-200);
}

.button.medium {
  padding: var(--size-space-300);
}

.button.primary, .button.neutral {
  border: 1px solid;
}

.button.state-1-hover.subtle {
  border-color: var(--color-border-default-default);
}

.button.neutral.state-1-default {
  background-color: var(--color-background-neutral-tertiary);
  border-color: var(--color-border-neutral-secondary);
}

.button.neutral.state-1-hover {
  background-color: var(--color-background-neutral-tertiary-hover);
  border-color: var(--color-border-neutral-secondary);
}

.button.state-1-default.primary {
  background-color: var(--color-background-brand-default);
  border-color: var(--color-border-brand-default);
}

.button.state-1-hover.primary {
  background-color: var(--color-background-brand-hover);
  border-color: var(--color-border-brand-default);
}

.button.state-1-disabled .text-wrapper {
  color: var(--color-text-disabled-on-disabled);
}

.button.state-1-hover.subtle .text-wrapper, .button.neutral.state-1-default .text-wrapper, .button.neutral.state-1-hover .text-wrapper {
  color: var(--color-text-default-default);
}

.button.state-1-default.primary .text-wrapper, .button.state-1-hover.primary .text-wrapper {
  color: var(--color-text-brand-on-brand);
}

.button.state-1-default.small.subtle .text-wrapper {
  color: var(--color-text-default-default);
}

.button.state-1-default.subtle.medium .text-wrapper {
  color: var(--color-text-neutral-default);
}

.button-group {
  gap: var(--size-space-400);
  width: 240px;
  display: flex;
  position: relative;
}

.button-group .class {
  flex: 1 !important;
  display: flex !important;
}

.button-group .class-2 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.button-group .class-3 {
  flex: none !important;
}

.button-group.end {
  justify-content: flex-end;
  align-items: center;
}

.button-group.justify {
  align-items: center;
}

.button-group.center {
  justify-content: center;
  align-items: center;
}

.button-group.start {
  align-items: center;
}

.button-group.stack {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.input-field {
  align-items: flex-start;
  gap: var(--size-space-200);
  flex-direction: column;
  display: inline-flex;
  position: relative;
}

.input-field .label {
  font-family: var(--body-base-font-family);
  font-size: var(--body-base-font-size);
  font-style: var(--body-base-font-style);
  font-weight: var(--body-base-font-weight);
  letter-spacing: var(--body-base-letter-spacing);
  line-height: var(--body-base-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.input-field .input {
  border-radius: var(--size-radius-200);
  padding: var(--size-space-300) var(--size-space-400) var(--size-space-300) var(--size-space-400);
  border: 1px solid;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  min-width: 240px;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.input-field .value {
  font-family: var(--single-line-body-base-font-family);
  font-size: var(--single-line-body-base-font-size);
  font-style: var(--single-line-body-base-font-style);
  font-weight: var(--single-line-body-base-font-weight);
  letter-spacing: var(--single-line-body-base-letter-spacing);
  line-height: var(--single-line-body-base-line-height);
  flex: 1;
  margin-top: -.5px;
  position: relative;
}

.input-field .disabled {
  color: var(--color-text-disabled-default);
}

.input-field .error, .input-field .default {
  color: var(--color-text-default-default);
}

.input-field .state-disabled {
  background-color: var(--color-background-disabled-default);
  border-color: var(--color-border-disabled-secondary);
}

.input-field .state-error {
  background-color: var(--color-background-default-default);
  border-color: var(--color-border-danger-default);
}

.input-field .state-default {
  background-color: var(--color-background-default-default);
  border-color: var(--color-border-default-default);
}

.input-field .state-0-disabled {
  color: var(--color-text-disabled-on-disabled);
}

.input-field .value-type-placeholder.state-0-error {
  color: var(--color-text-default-tertiary);
}

.input-field .value-type-default.state-0-error, .input-field .value-type-default.state-0-default {
  color: var(--color-text-default-default);
}

.input-field .value-type-placeholder.state-0-default {
  color: var(--color-text-default-tertiary);
}

.text-link {
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.text-link .div {
  color: var(--color-text-default-default);
  font-family: var(--body-link-font-family);
  font-size: var(--body-link-font-size);
  font-style: var(--body-link-font-style);
  font-weight: var(--body-link-font-weight);
  letter-spacing: var(--body-link-letter-spacing);
  line-height: var(--body-link-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  text-decoration: underline;
  position: relative;
}

.form-log-in {
  background-color: var(--color-background-default-default);
  border: 1px solid;
  border-color: var(--color-border-default-default);
  border-radius: var(--size-radius-200);
  align-items: flex-start;
  gap: var(--size-space-600);
  padding: var(--size-space-600);
  flex-direction: column;
  min-width: 320px;
  display: inline-flex;
  position: relative;
}

.form-log-in .design-component-instance-node {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.form-log-in .button-group-instance {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.login {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.login .div-4 {
  background-color: #fff;
  width: 1440px;
  height: 800px;
  position: relative;
  overflow: hidden;
}

.login .illustration {
  background-color: #f3d083;
  width: 801px;
  height: 800px;
  position: absolute;
  top: 0;
  left: -1px;
}

.login .overlap-12 {
  background-image: url("path.5c104899.svg");
  background-size: 100% 100%;
  width: 747px;
  height: 742px;
  position: relative;
  top: 58px;
  left: 1px;
}

.login .path {
  width: 720px;
  height: 708px;
  position: absolute;
  top: 34px;
  left: 0;
}

.login .path-2 {
  width: 653px;
  height: 252px;
  position: absolute;
  top: 490px;
  left: 0;
}

.login .path-3 {
  width: 527px;
  height: 312px;
  position: absolute;
  top: 430px;
  left: 0;
}

.login .oval {
  width: 201px;
  height: 52px;
  position: absolute;
  top: 509px;
  left: 122px;
}

.login .oval-2 {
  width: 123px;
  height: 35px;
  position: absolute;
  top: 544px;
  left: 568px;
}

.login .group-8 {
  width: 133px;
  height: 302px;
  position: absolute;
  top: 273px;
  left: 566px;
}

.login .overlap-group-5 {
  height: 302px;
  position: relative;
}

.login .path-4 {
  width: 25px;
  height: 24px;
  position: absolute;
  top: 278px;
  left: 76px;
}

.login .path-5 {
  width: 22px;
  height: 65px;
  position: absolute;
  top: 214px;
  left: 82px;
}

.login .path-6 {
  width: 27px;
  height: 17px;
  position: absolute;
  top: 271px;
  left: 34px;
}

.login .path-7 {
  width: 22px;
  height: 61px;
  position: absolute;
  top: 212px;
  left: 46px;
}

.login .path-8 {
  width: 50px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 78px;
}

.login .path-9 {
  width: 58px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 51px;
}

.login .path-10 {
  width: 97px;
  height: 169px;
  position: absolute;
  top: 57px;
  left: 31px;
}

.login .shape {
  width: 18px;
  height: 78px;
  position: absolute;
  top: 66px;
  left: 110px;
}

.login .path-11 {
  width: 22px;
  height: 32px;
  position: absolute;
  top: 60px;
  left: 38px;
}

.login .path-12 {
  width: 30px;
  height: 135px;
  position: absolute;
  top: 88px;
  left: 93px;
}

.login .path-13 {
  width: 19px;
  height: 8px;
  position: absolute;
  top: 118px;
  left: 66px;
}

.login .path-14 {
  width: 52px;
  height: 52px;
  position: absolute;
  top: 61px;
  left: 0;
}

.login .path-15 {
  width: 10px;
  height: 12px;
  position: absolute;
  top: 84px;
  left: 35px;
}

.login .path-16 {
  width: 32px;
  height: 52px;
  position: absolute;
  top: 61px;
  left: 0;
}

.login .path-17 {
  width: 45px;
  height: 56px;
  position: absolute;
  top: 95px;
  left: 88px;
}

.login .path-18 {
  width: 16px;
  height: 45px;
  position: absolute;
  top: 95px;
  left: 117px;
}

.login .path-19 {
  width: 23px;
  height: 21px;
  position: absolute;
  top: 48px;
  left: 66px;
}

.login .path-20 {
  width: 46px;
  height: 44px;
  position: absolute;
  top: 15px;
  left: 56px;
}

.login .group-9 {
  width: 464px;
  height: 415px;
  position: absolute;
  top: 38px;
  left: 77px;
}

.login .overlap-13 {
  width: 464px;
  height: 394px;
  position: absolute;
  top: 22px;
  left: 0;
}

.login .overlap-14 {
  width: 464px;
  height: 394px;
  position: absolute;
  top: 0;
  left: 0;
}

.login .overlap-15 {
  width: 408px;
  height: 354px;
  position: absolute;
  top: 40px;
  left: 55px;
}

.login .path-21 {
  width: 170px;
  height: 229px;
  position: absolute;
  top: 124px;
  left: 1px;
}

.login .path-22 {
  width: 137px;
  height: 229px;
  position: absolute;
  top: 125px;
  left: 1px;
}

.login .path-23 {
  width: 33px;
  height: 123px;
  position: absolute;
  top: 174px;
  left: 137px;
}

.login .path-24 {
  width: 27px;
  height: 34px;
  position: absolute;
  top: 141px;
  left: 82px;
}

.login .path-25 {
  width: 30px;
  height: 76px;
  position: absolute;
  top: 168px;
  left: 94px;
}

.login .path-26 {
  width: 53px;
  height: 106px;
  position: absolute;
  top: 138px;
  left: 64px;
}

.login .path-27 {
  width: 62px;
  height: 122px;
  position: absolute;
  top: 124px;
  left: 47px;
}

.login .path-28 {
  width: 20px;
  height: 108px;
  position: absolute;
  top: 137px;
  left: 112px;
}

.login .path-29 {
  width: 147px;
  height: 135px;
  position: absolute;
  top: 161px;
  left: 0;
}

.login .path-30 {
  width: 15px;
  height: 28px;
  position: absolute;
  top: 216px;
  left: 40px;
}

.login .path-31 {
  width: 87px;
  height: 135px;
  position: absolute;
  top: 161px;
  left: 0;
}

.login .path-32 {
  width: 17px;
  height: 35px;
  position: absolute;
  top: 236px;
  left: 131px;
}

.login .path-33 {
  width: 26px;
  height: 57px;
  position: absolute;
  top: 217px;
  left: 166px;
}

.login .path-34 {
  width: 69px;
  height: 42px;
  position: absolute;
  top: 225px;
  left: 131px;
}

.login .path-35 {
  width: 125px;
  height: 107px;
  position: absolute;
  top: 0;
  left: 18px;
}

.login .path-36 {
  width: 25px;
  height: 32px;
  position: absolute;
  top: 128px;
  left: 90px;
}

.login .path-37 {
  width: 102px;
  height: 108px;
  position: absolute;
  top: 40px;
  left: 34px;
}

.login .path-38 {
  width: 24px;
  height: 19px;
  position: absolute;
  top: 128px;
  left: 78px;
}

.login .shape-2 {
  width: 82px;
  height: 55px;
  position: absolute;
  top: 40px;
  left: 47px;
}

.login .path-39 {
  width: 38px;
  height: 50px;
  position: absolute;
  top: 121px;
  left: 54px;
}

.login .path-40 {
  width: 147px;
  height: 158px;
  position: absolute;
  top: 120px;
  left: 148px;
}

.login .oval-3 {
  width: 122px;
  height: 162px;
  position: absolute;
  top: 118px;
  left: 248px;
}

.login .path-41 {
  width: 74px;
  height: 74px;
  position: absolute;
  top: 161px;
  left: 248px;
}

.login .path-42 {
  width: 84px;
  height: 162px;
  position: absolute;
  top: 118px;
  left: 248px;
}

.login .shape-3 {
  width: 125px;
  height: 165px;
  position: absolute;
  top: 116px;
  left: 246px;
}

.login .path-43 {
  width: 66px;
  height: 34px;
  position: absolute;
  top: 128px;
  left: 326px;
}

.login .path-44 {
  width: 70px;
  height: 21px;
  position: absolute;
  top: 191px;
  left: 339px;
}

.login .path-45 {
  width: 65px;
  height: 54px;
  position: absolute;
  top: 229px;
  left: 323px;
}

.login .path-46 {
  width: 37px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 218px;
}

.login .path-47 {
  width: 19px;
  height: 23px;
  position: absolute;
  top: 72px;
  left: 270px;
}

.login .path-48 {
  width: 56px;
  height: 25px;
  position: absolute;
  top: 122px;
  left: 0;
}

.login .path-49 {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 40px;
  left: 28px;
}

.login .path-50 {
  width: 18px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 149px;
}

.login .text-11 {
  width: 384px;
  height: 91px;
  position: absolute;
  top: 197px;
  left: 928px;
}

.login .text-12 {
  width: 388px;
  height: 91px;
  position: relative;
}

.login .get-more-things-done {
  color: #262626;
  letter-spacing: .3px;
  width: 384px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.login .search-sourcing-the {
  color: #272727d9;
  letter-spacing: .26px;
  width: 384px;
  font-family: Montserrat, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.9px;
  position: absolute;
  top: 45px;
  left: 0;
}

.login .form-log-in-instance {
  min-width: unset !important;
  width: 444px !important;
  height: 395px !important;
  display: flex !important;
  position: absolute !important;
  top: 329px !important;
  left: 878px !important;
}

.login .form-log-in-2 {
  overflow: unset !important;
}

.login .box {
  z-index: 16;
  flex-flow: column;
  align-items: flex-start;
  gap: 8px;
  width: 387px;
  height: 117px;
  margin: 54.25px 0 0 4px;
  display: flex;
  position: relative;
  top: 400px;
  right: -900px;
}

.login .text-3 {
  color: #1e1e1e;
  font-family: Inter, var(--default-font-family);
  text-align: left;
  white-space: nowrap;
  z-index: 17;
  flex-shrink: 0;
  flex-basis: auto;
  align-self: stretch;
  min-width: 0;
  height: 22px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  position: relative;
  top: 100px;
  right: -700px;
}

.login .input {
  z-index: 18;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  flex-wrap: nowrap;
  flex-shrink: 0;
  align-self: stretch;
  align-items: center;
  min-width: 240px;
  padding: 12px 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.login .value {
  color: #1e1e1e;
  font-family: Inter, var(--default-font-family);
  text-align: left;
  white-space: nowrap;
  z-index: 19;
  flex: 1 0 auto;
  height: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.login .button {
  cursor: pointer;
  z-index: 14;
  background: #2c2c2c;
  border: 1px solid #2c2c2c;
  border-radius: 8px;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 396px;
  margin: 12px 0 0;
  padding: 12px;
  display: flex;
  position: relative;
  top: 430px;
  right: -900px;
  overflow: hidden;
}

.login .button-enter {
  color: #f5f5f5;
  font-family: Inter, var(--default-font-family);
  text-align: left;
  white-space: nowrap;
  z-index: 15;
  flex-shrink: 0;
  flex-basis: auto;
  height: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.podcast {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.podcast .div-6 {
  background-color: #fff;
  width: 1440px;
  height: 1024px;
  position: relative;
  overflow: hidden;
}

.podcast .overlap-18 {
  background-color: #d9d9d9;
  border-radius: 10px;
  width: 52px;
  height: 52px;
  position: absolute;
  top: 42px;
  left: 1336px;
}

.podcast .icon-instance-node-2 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 14px !important;
  left: 14px !important;
}

.podcast .group-13 {
  width: 224px;
  height: 72px;
  position: absolute;
  top: 32px;
  left: 156px;
}

.podcast .group-14 {
  width: 228px;
  height: 72px;
  position: relative;
}

.podcast .text-wrapper-100 {
  color: #3e435d;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.podcast .text-wrapper-101 {
  color: #ada7a7;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 48px;
  left: 0;
}

.podcast .group-15 {
  width: 356px;
  height: 52px;
  position: absolute;
  top: 42px;
  left: 964px;
}

.podcast .overlap-group-8 {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  width: 354px;
  height: 52px;
  position: relative;
}

.podcast .vuesax-linear-search-normal-1 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 13px !important;
  left: 19px !important;
}

.podcast .text-wrapper-102 {
  color: #ada7a7;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 13px;
  left: 59px;
}

.podcast .vector-12 {
  width: 1px;
  height: 1024px;
  position: absolute;
  top: 0;
  left: 116px;
}

.podcast .group-16 {
  width: 54px;
  height: 784px;
  position: absolute;
  top: 208px;
  left: 32px;
}

.podcast .vuesax-bold-logout-2-wrapper {
  background-color: #18a0fb;
  border-radius: 8px;
  width: 52px;
  height: 52px;
  display: block;
  position: absolute;
  top: 732px;
  left: 0;
}

.podcast .text-wrapper-103 {
  color: #000;
  letter-spacing: 0;
  opacity: .3;
  font-family: DM Sans, Helvetica;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 8px;
}

.podcast .vuesax-bold-element-3-1 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 38px !important;
  left: 14px !important;
}

.podcast .vuesax-bold-discover-2 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 94px !important;
  left: 14px !important;
}

.podcast .group-17 {
  width: 214px;
  height: 52px;
  display: block;
  position: absolute;
  top: 136px;
  left: 1184px;
}

.podcast .overlap-19 {
  border-radius: 8px;
  width: 212px;
  height: 52px;
  position: relative;
}

.podcast .rectangle-5 {
  opacity: .1;
  background-color: #18a0fb;
  border-radius: 8px;
  width: 212px;
  height: 52px;
  position: absolute;
  top: 0;
  left: 0;
}

.podcast .text-wrapper-104 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 20px;
}

.podcast .group-18 {
  width: 196px;
  height: 52px;
  position: absolute;
  top: 136px;
  left: 973px;
}

.podcast .overlap-20 {
  border-radius: 8px;
  width: 194px;
  height: 52px;
  position: relative;
}

.podcast .rectangle-6 {
  opacity: .1;
  background-color: red;
  border-radius: 8px;
  width: 194px;
  height: 52px;
  position: absolute;
  top: 0;
  left: 0;
}

.podcast .text-wrapper-105 {
  color: #fb1818;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 12px;
}

.podcast .text-wrapper-106 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 147px;
  left: 156px;
}

.podcast .overlap-21 {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  width: 1244px;
  height: 835px;
  position: absolute;
  top: 220px;
  left: 156px;
}

.podcast .vuesax-linear-minus-5 {
  background-image: url("vuesax-linear-minus-square-2.e4eeb1b9.svg") !important;
  position: absolute !important;
  top: 23px !important;
  left: 23px !important;
}

.podcast .vuesax-linear-minus-6 {
  background-image: url("vuesax-linear-minus-square-2.e4eeb1b9.svg") !important;
  position: absolute !important;
  top: 114px !important;
  left: 23px !important;
}

.podcast .vuesax-linear-minus-7 {
  background-image: url("vuesax-linear-minus-square-2.e4eeb1b9.svg") !important;
  position: absolute !important;
  top: 334px !important;
  left: 23px !important;
}

.podcast .vuesax-linear-minus-8 {
  background-image: url("vuesax-linear-minus-square-2.e4eeb1b9.svg") !important;
  position: absolute !important;
  top: 444px !important;
  left: 23px !important;
}

.podcast .vuesax-linear-minus-9 {
  background-image: url("vuesax-linear-minus-square-2.e4eeb1b9.svg") !important;
  position: absolute !important;
  top: 664px !important;
  left: 23px !important;
}

.podcast .vuesax-linear-minus-10 {
  background-image: url("vuesax-linear-minus-square-2.e4eeb1b9.svg") !important;
  position: absolute !important;
  top: 774px !important;
  left: 23px !important;
}

.podcast .text-wrapper-107 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 71px;
}

.podcast .text-wrapper-108 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 251px;
}

.podcast .text-wrapper-109 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 113px;
  left: 251px;
}

.podcast .text-wrapper-110 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 223px;
  left: 251px;
}

.podcast .text-wrapper-111 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 333px;
  left: 251px;
}

.podcast .text-wrapper-112 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 443px;
  left: 251px;
}

.podcast .text-wrapper-113 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 553px;
  left: 251px;
}

.podcast .text-wrapper-114 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 663px;
  left: 251px;
}

.podcast .text-wrapper-115 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 773px;
  left: 251px;
}

.podcast .text-wrapper-116 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 113px;
  left: 570px;
}

.podcast .text-wrapper-117 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 223px;
  left: 570px;
}

.podcast .text-wrapper-118 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 333px;
  left: 570px;
}

.podcast .text-wrapper-119 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 443px;
  left: 570px;
}

.podcast .text-wrapper-120 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 553px;
  left: 570px;
}

.podcast .text-wrapper-121 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 663px;
  left: 570px;
}

.podcast .text-wrapper-122 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 773px;
  left: 570px;
}

.podcast .text-wrapper-123 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 113px;
  left: 739px;
}

.podcast .text-wrapper-124 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 223px;
  left: 739px;
}

.podcast .text-wrapper-125 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 333px;
  left: 739px;
}

.podcast .text-wrapper-126 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 443px;
  left: 739px;
}

.podcast .text-wrapper-127 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 553px;
  left: 739px;
}

.podcast .text-wrapper-128 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 663px;
  left: 739px;
}

.podcast .text-wrapper-129 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 773px;
  left: 739px;
}

.podcast .text-wrapper-130 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 113px;
  left: 959px;
}

.podcast .text-wrapper-131 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 223px;
  left: 959px;
}

.podcast .text-wrapper-132 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 333px;
  left: 959px;
}

.podcast .text-wrapper-133 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 443px;
  left: 959px;
}

.podcast .text-wrapper-134 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 553px;
  left: 959px;
}

.podcast .text-wrapper-135 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 663px;
  left: 959px;
}

.podcast .text-wrapper-136 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 773px;
  left: 959px;
}

.podcast .text-wrapper-137 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 570px;
}

.podcast .text-wrapper-138 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 739px;
}

.podcast .text-wrapper-139 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 959px;
}

.podcast .vector-13 {
  object-fit: cover;
  width: 1196px;
  height: 1px;
  position: absolute;
  top: 70px;
  left: 23px;
}

.podcast .vector-14 {
  object-fit: cover;
  width: 1196px;
  height: 1px;
  position: absolute;
  top: 180px;
  left: 23px;
}

.podcast .vector-15 {
  object-fit: cover;
  width: 1196px;
  height: 1px;
  position: absolute;
  top: 290px;
  left: 23px;
}

.podcast .vector-16 {
  object-fit: cover;
  width: 1196px;
  height: 1px;
  position: absolute;
  top: 400px;
  left: 23px;
}

.podcast .vector-17 {
  object-fit: cover;
  width: 1196px;
  height: 1px;
  position: absolute;
  top: 510px;
  left: 23px;
}

.podcast .vector-18 {
  object-fit: cover;
  width: 1196px;
  height: 1px;
  position: absolute;
  top: 620px;
  left: 23px;
}

.podcast .vector-19 {
  object-fit: cover;
  width: 1196px;
  height: 1px;
  position: absolute;
  top: 730px;
  left: 23px;
}

.podcast .overlap-22 {
  background-color: #d9d9d9;
  border-radius: 6px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 92px;
  left: 72px;
}

.podcast .vuesax-bold-gallery-12 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 23px !important;
  left: 48px !important;
}

.podcast .overlap-23 {
  background-color: #d9d9d9;
  border-radius: 6px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 202px;
  left: 72px;
}

.podcast .overlap-24 {
  background-color: #d9d9d9;
  border-radius: 6px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 312px;
  left: 72px;
}

.podcast .overlap-25 {
  background-color: #d9d9d9;
  border-radius: 6px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 422px;
  left: 72px;
}

.podcast .overlap-26 {
  background-color: #d9d9d9;
  border-radius: 6px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 532px;
  left: 72px;
}

.podcast .overlap-27 {
  background-color: #d9d9d9;
  border-radius: 6px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 642px;
  left: 72px;
}

.podcast .overlap-28 {
  background-color: #d9d9d9;
  border-radius: 6px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 752px;
  left: 72px;
}

.podcast .vuesax-bold-link-6 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 114px !important;
  left: 1137px !important;
}

.podcast .vuesax-bold-link-7 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 224px !important;
  left: 1137px !important;
}

.podcast .vuesax-bold-link-8 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 334px !important;
  left: 1137px !important;
}

.podcast .vuesax-bold-link-9 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 444px !important;
  left: 1137px !important;
}

.podcast .vuesax-bold-link-10 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 554px !important;
  left: 1137px !important;
}

.podcast .vuesax-bold-link-11 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 664px !important;
  left: 1137px !important;
}

.podcast .vuesax-bold-link-12 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 774px !important;
  left: 1137px !important;
}

.podcast .vuesax-bold-edit-2-9 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 114px !important;
  left: 1185px !important;
}

.podcast .vuesax-bold-edit-2-12 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 224px !important;
  left: 1185px !important;
}

.podcast .vuesax-bold-edit-2-9-instance {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 334px !important;
  left: 1185px !important;
}

.podcast .vuesax-bold-edit-2-12-instance {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 444px !important;
  left: 1185px !important;
}

.podcast .vuesax-bold-edit-3 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 554px !important;
  left: 1185px !important;
}

.podcast .vuesax-bold-edit-4 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 664px !important;
  left: 1185px !important;
}

.podcast .vuesax-bold-edit-5 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 774px !important;
  left: 1185px !important;
}

.podcast .vuesax-bold-tick-square-1-instance {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 224px !important;
  left: 23px !important;
}

.podcast .vuesax-bold-tick-2 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 554px !important;
  left: 23px !important;
}

.podcast .overlap-29 {
  background-color: #d9d9d9;
  width: 66px;
  height: 61px;
  position: absolute;
  top: 37px;
  left: 33px;
}

.podcast .vuesax-bold-gallery-13-instance {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 19px !important;
  left: 21px !important;
}

.website {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.website .div-7 {
  background-color: #fff;
  width: 1440px;
  height: 800px;
  position: relative;
  overflow: hidden;
}

.website .illustration-2 {
  background-color: #f3d083;
  width: 801px;
  height: 800px;
  position: absolute;
  top: 0;
  left: -1px;
}

.website .overlap-30 {
  background-image: url("path.5c104899.svg");
  background-size: 100% 100%;
  width: 747px;
  height: 742px;
  position: relative;
  top: 58px;
  left: 1px;
}

.website .path-51 {
  width: 720px;
  height: 708px;
  position: absolute;
  top: 34px;
  left: 0;
}

.website .path-52 {
  width: 653px;
  height: 252px;
  position: absolute;
  top: 490px;
  left: 0;
}

.website .path-53 {
  width: 527px;
  height: 312px;
  position: absolute;
  top: 430px;
  left: 0;
}

.website .oval-4 {
  width: 201px;
  height: 52px;
  position: absolute;
  top: 509px;
  left: 122px;
}

.website .oval-5 {
  width: 123px;
  height: 35px;
  position: absolute;
  top: 544px;
  left: 568px;
}

.website .group-19 {
  width: 133px;
  height: 302px;
  position: absolute;
  top: 273px;
  left: 566px;
}

.website .overlap-group-9 {
  height: 302px;
  position: relative;
}

.website .path-54 {
  width: 25px;
  height: 24px;
  position: absolute;
  top: 278px;
  left: 76px;
}

.website .path-55 {
  width: 22px;
  height: 65px;
  position: absolute;
  top: 214px;
  left: 82px;
}

.website .path-56 {
  width: 27px;
  height: 17px;
  position: absolute;
  top: 271px;
  left: 34px;
}

.website .path-57 {
  width: 22px;
  height: 61px;
  position: absolute;
  top: 212px;
  left: 46px;
}

.website .path-58 {
  width: 50px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 78px;
}

.website .path-59 {
  width: 58px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 51px;
}

.website .path-60 {
  width: 97px;
  height: 169px;
  position: absolute;
  top: 57px;
  left: 31px;
}

.website .shape-4 {
  width: 18px;
  height: 78px;
  position: absolute;
  top: 66px;
  left: 110px;
}

.website .path-61 {
  width: 22px;
  height: 32px;
  position: absolute;
  top: 60px;
  left: 38px;
}

.website .path-62 {
  width: 30px;
  height: 135px;
  position: absolute;
  top: 88px;
  left: 93px;
}

.website .path-63 {
  width: 19px;
  height: 8px;
  position: absolute;
  top: 118px;
  left: 66px;
}

.website .path-64 {
  width: 52px;
  height: 52px;
  position: absolute;
  top: 61px;
  left: 0;
}

.website .path-65 {
  width: 10px;
  height: 12px;
  position: absolute;
  top: 84px;
  left: 35px;
}

.website .path-66 {
  width: 32px;
  height: 52px;
  position: absolute;
  top: 61px;
  left: 0;
}

.website .path-67 {
  width: 45px;
  height: 56px;
  position: absolute;
  top: 95px;
  left: 88px;
}

.website .path-68 {
  width: 16px;
  height: 45px;
  position: absolute;
  top: 95px;
  left: 117px;
}

.website .path-69 {
  width: 23px;
  height: 21px;
  position: absolute;
  top: 48px;
  left: 66px;
}

.website .path-70 {
  width: 46px;
  height: 44px;
  position: absolute;
  top: 15px;
  left: 56px;
}

.website .group-20 {
  width: 464px;
  height: 415px;
  position: absolute;
  top: 38px;
  left: 77px;
}

.website .overlap-31 {
  width: 464px;
  height: 394px;
  position: absolute;
  top: 22px;
  left: 0;
}

.website .overlap-32 {
  width: 464px;
  height: 394px;
  position: absolute;
  top: 0;
  left: 0;
}

.website .overlap-33 {
  width: 408px;
  height: 354px;
  position: absolute;
  top: 40px;
  left: 55px;
}

.website .path-71 {
  width: 170px;
  height: 229px;
  position: absolute;
  top: 124px;
  left: 1px;
}

.website .path-72 {
  width: 137px;
  height: 229px;
  position: absolute;
  top: 125px;
  left: 1px;
}

.website .path-73 {
  width: 33px;
  height: 123px;
  position: absolute;
  top: 174px;
  left: 137px;
}

.website .path-74 {
  width: 27px;
  height: 34px;
  position: absolute;
  top: 141px;
  left: 82px;
}

.website .path-75 {
  width: 30px;
  height: 76px;
  position: absolute;
  top: 168px;
  left: 94px;
}

.website .path-76 {
  width: 53px;
  height: 106px;
  position: absolute;
  top: 138px;
  left: 64px;
}

.website .path-77 {
  width: 62px;
  height: 122px;
  position: absolute;
  top: 124px;
  left: 47px;
}

.website .path-78 {
  width: 20px;
  height: 108px;
  position: absolute;
  top: 137px;
  left: 112px;
}

.website .path-79 {
  width: 147px;
  height: 135px;
  position: absolute;
  top: 161px;
  left: 0;
}

.website .path-80 {
  width: 15px;
  height: 28px;
  position: absolute;
  top: 216px;
  left: 40px;
}

.website .path-81 {
  width: 87px;
  height: 135px;
  position: absolute;
  top: 161px;
  left: 0;
}

.website .path-82 {
  width: 17px;
  height: 35px;
  position: absolute;
  top: 236px;
  left: 131px;
}

.website .path-83 {
  width: 26px;
  height: 57px;
  position: absolute;
  top: 217px;
  left: 166px;
}

.website .path-84 {
  width: 69px;
  height: 42px;
  position: absolute;
  top: 225px;
  left: 131px;
}

.website .path-85 {
  width: 125px;
  height: 107px;
  position: absolute;
  top: 0;
  left: 18px;
}

.website .path-86 {
  width: 25px;
  height: 32px;
  position: absolute;
  top: 128px;
  left: 90px;
}

.website .path-87 {
  width: 102px;
  height: 108px;
  position: absolute;
  top: 40px;
  left: 34px;
}

.website .path-88 {
  width: 24px;
  height: 19px;
  position: absolute;
  top: 128px;
  left: 78px;
}

.website .shape-5 {
  width: 82px;
  height: 55px;
  position: absolute;
  top: 40px;
  left: 47px;
}

.website .path-89 {
  width: 38px;
  height: 50px;
  position: absolute;
  top: 121px;
  left: 54px;
}

.website .path-90 {
  width: 147px;
  height: 158px;
  position: absolute;
  top: 120px;
  left: 148px;
}

.website .oval-6 {
  width: 122px;
  height: 162px;
  position: absolute;
  top: 118px;
  left: 248px;
}

.website .path-91 {
  width: 74px;
  height: 74px;
  position: absolute;
  top: 161px;
  left: 248px;
}

.website .path-92 {
  width: 84px;
  height: 162px;
  position: absolute;
  top: 118px;
  left: 248px;
}

.website .shape-6 {
  width: 125px;
  height: 165px;
  position: absolute;
  top: 116px;
  left: 246px;
}

.website .path-93 {
  width: 66px;
  height: 34px;
  position: absolute;
  top: 128px;
  left: 326px;
}

.website .path-94 {
  width: 70px;
  height: 21px;
  position: absolute;
  top: 191px;
  left: 339px;
}

.website .path-95 {
  width: 65px;
  height: 54px;
  position: absolute;
  top: 229px;
  left: 323px;
}

.website .path-96 {
  width: 37px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 218px;
}

.website .path-97 {
  width: 19px;
  height: 23px;
  position: absolute;
  top: 72px;
  left: 270px;
}

.website .path-98 {
  width: 56px;
  height: 25px;
  position: absolute;
  top: 122px;
  left: 0;
}

.website .path-99 {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 40px;
  left: 28px;
}

.website .path-100 {
  width: 18px;
  height: 22px;
  position: absolute;
  top: 0;
  left: 149px;
}

.website .text-20 {
  width: 384px;
  height: 91px;
  position: absolute;
  top: 197px;
  left: 928px;
}

.website .text-21 {
  width: 388px;
  height: 91px;
  position: relative;
}

.website .get-more-things-done-2 {
  color: #262626;
  letter-spacing: .3px;
  width: 384px;
  font-family: Montserrat, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.website .search-sourcing-the-2 {
  color: #272727d9;
  letter-spacing: .26px;
  width: 384px;
  font-family: Montserrat, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.9px;
  position: absolute;
  top: 45px;
  left: 0;
}

.website .form-log-in-3 {
  min-width: unset !important;
  width: 444px !important;
  height: 395px !important;
  display: flex !important;
  position: absolute !important;
  top: 329px !important;
  left: 878px !important;
}

.website .form-log-in-4 {
  overflow: unset !important;
}

.vuesax-bold-edit-2 {
  visibility: hidden;
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.vuesax-bold-logout {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.episodios {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.episodios .div-8 {
  background-color: #fff;
  width: 1440px;
  height: 1024px;
  position: relative;
  overflow: hidden;
}

.episodios .rectangle-7 {
  background-color: #d9d9d9;
  border-radius: 10px;
  width: 52px;
  height: 52px;
  position: absolute;
  top: 42px;
  left: 1336px;
}

.episodios .group-21 {
  width: 224px;
  height: 72px;
  position: absolute;
  top: 32px;
  left: 156px;
}

.episodios .group-22 {
  width: 228px;
  height: 72px;
  position: relative;
}

.episodios .text-wrapper-140 {
  color: #3e435d;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.episodios .text-wrapper-141 {
  color: #ada7a7;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 48px;
  left: 0;
}

.episodios .group-23 {
  width: 356px;
  height: 52px;
  position: absolute;
  top: 42px;
  left: 964px;
}

.episodios .rectangle-8 {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  width: 354px;
  height: 52px;
  position: absolute;
  top: 0;
  left: 0;
}

.episodios .vuesax-linear-search-normal-1-instance {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 14px !important;
  left: 20px !important;
}

.episodios .text-wrapper-142 {
  color: #ada7a7;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 60px;
}

.episodios .vector-20 {
  width: 1px;
  height: 1024px;
  position: absolute;
  top: 0;
  left: 116px;
}

.episodios .group-24 {
  width: 54px;
  height: 784px;
  position: absolute;
  top: 208px;
  left: 32px;
}

.episodios .group-25 {
  width: 52px;
  height: 52px;
  display: block;
  position: absolute;
  top: 732px;
  left: 0;
}

.episodios .rectangle-9 {
  background-color: #18a0fb;
  border-radius: 8px;
  width: 52px;
  height: 52px;
  position: absolute;
  top: 0;
  left: 0;
}

.episodios .text-wrapper-143 {
  color: #000;
  letter-spacing: 0;
  opacity: .3;
  font-family: DM Sans, Helvetica;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 8px;
}

.episodios .vuesax-bold-element-3-2 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 38px !important;
  left: 14px !important;
}

.episodios .vuesax-bold-discover-1 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 94px !important;
  left: 14px !important;
}

.episodios .group-26 {
  width: 154px;
  height: 52px;
  position: absolute;
  top: 136px;
  left: 1210px;
}

.episodios .rectangle-10 {
  opacity: .1;
  background-color: #18a0fb;
  border-radius: 8px;
  width: 152px;
  height: 52px;
  position: absolute;
  top: 0;
  left: 0;
}

.episodios .text-wrapper-144 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 20px;
}

.episodios .group-27 {
  width: 196px;
  height: 52px;
  position: absolute;
  top: 136px;
  left: 999px;
}

.episodios .rectangle-11 {
  opacity: .1;
  background-color: red;
  border-radius: 8px;
  width: 194px;
  height: 52px;
  position: absolute;
  top: 0;
  left: 0;
}

.episodios .text-wrapper-145 {
  color: #fb1818;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 35px;
}

.episodios .rectangle-12 {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  width: 1244px;
  height: 835px;
  position: absolute;
  top: 220px;
  left: 156px;
}

.episodios .vuesax-linear-minus-wrapper {
  opacity: .5;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 244px;
  left: 180px;
}

.episodios .vuesax-linear-minus-square-2 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.episodios .vuesax-linear-minus-square-2-wrapper {
  opacity: .5;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 335px;
  left: 180px;
}

.episodios .vuesax-linear-minus-square-2-instance-wrapper {
  opacity: .5;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 555px;
  left: 180px;
}

.episodios .vuesax-linear-minus-11 {
  opacity: .5;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 665px;
  left: 180px;
}

.episodios .vuesax-linear-minus-12 {
  opacity: .5;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 885px;
  left: 180px;
}

.episodios .vuesax-linear-minus-13 {
  opacity: .5;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 995px;
  left: 180px;
}

.episodios .text-wrapper-146 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 243px;
  left: 228px;
}

.episodios .text-wrapper-147 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 243px;
  left: 408px;
}

.episodios .text-wrapper-148 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 334px;
  left: 896px;
}

.episodios .text-wrapper-149 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 444px;
  left: 896px;
}

.episodios .text-wrapper-150 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 554px;
  left: 896px;
}

.episodios .text-wrapper-151 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 664px;
  left: 896px;
}

.episodios .text-wrapper-152 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 774px;
  left: 896px;
}

.episodios .text-wrapper-153 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 884px;
  left: 896px;
}

.episodios .text-wrapper-154 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 994px;
  left: 896px;
}

.episodios .text-wrapper-155 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 334px;
  left: 1116px;
}

.episodios .text-wrapper-156 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 444px;
  left: 1116px;
}

.episodios .text-wrapper-157 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 554px;
  left: 1116px;
}

.episodios .text-wrapper-158 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 664px;
  left: 1116px;
}

.episodios .text-wrapper-159 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 774px;
  left: 1116px;
}

.episodios .text-wrapper-160 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 884px;
  left: 1116px;
}

.episodios .text-wrapper-161 {
  color: #18a0fb;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 994px;
  left: 1116px;
}

.episodios .text-wrapper-162 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 243px;
  left: 727px;
}

.episodios .text-wrapper-163 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 243px;
  left: 1116px;
}

.episodios .vector-21 {
  object-fit: cover;
  width: 1196px;
  height: 1px;
  position: absolute;
  top: 292px;
  left: 180px;
}

.episodios .vector-22 {
  object-fit: cover;
  width: 1196px;
  height: 1px;
  position: absolute;
  top: 402px;
  left: 180px;
}

.episodios .vector-23 {
  object-fit: cover;
  width: 1196px;
  height: 1px;
  position: absolute;
  top: 512px;
  left: 180px;
}

.episodios .vector-24 {
  object-fit: cover;
  width: 1196px;
  height: 1px;
  position: absolute;
  top: 622px;
  left: 180px;
}

.episodios .vector-25 {
  object-fit: cover;
  width: 1196px;
  height: 1px;
  position: absolute;
  top: 732px;
  left: 180px;
}

.episodios .vector-26 {
  object-fit: cover;
  width: 1196px;
  height: 1px;
  position: absolute;
  top: 842px;
  left: 180px;
}

.episodios .vector-27 {
  object-fit: cover;
  width: 1196px;
  height: 1px;
  position: absolute;
  top: 952px;
  left: 180px;
}

.episodios .rectangle-13 {
  background-color: #d9d9d9;
  border-radius: 6px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 312px;
  left: 228px;
}

.episodios .rectangle-14 {
  background-color: #d9d9d9;
  border-radius: 6px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 422px;
  left: 228px;
}

.episodios .rectangle-15 {
  background-color: #d9d9d9;
  border-radius: 6px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 532px;
  left: 228px;
}

.episodios .rectangle-16 {
  background-color: #d9d9d9;
  border-radius: 6px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 642px;
  left: 228px;
}

.episodios .rectangle-17 {
  background-color: #d9d9d9;
  border-radius: 6px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 752px;
  left: 228px;
}

.episodios .rectangle-18 {
  background-color: #d9d9d9;
  border-radius: 6px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 862px;
  left: 228px;
}

.file-input {
  display: none;
}

.episodios .rectangle-19 {
  background-color: #d9d9d9;
  border-radius: 6px;
  width: 120px;
  height: 70px;
  position: absolute;
  top: 972px;
  left: 228px;
}

.episodios .vuesax-bold-logout-wrapper {
  background-color: #18a0fb;
  border-radius: 8px;
  width: 52px;
  height: 52px;
  display: block;
  position: absolute;
  top: 732px;
  left: 0;
}

.episodios .vuesax-bold-link-13 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 335px !important;
  left: 1294px !important;
}

.episodios .vuesax-bold-link-13-2 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 445px !important;
  left: 1230px !important;
}

.episodios .vuesax-bold-link-14 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 445px !important;
  left: 1294px !important;
}

.episodios .vuesax-bold-link-14-2 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 445px !important;
  left: 1230px !important;
}

.episodios .vuesax-bold-link-15 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 555px !important;
  left: 1294px !important;
}

.episodios .vuesax-bold-link-15-2 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 555px !important;
  left: 1230px !important;
}

.episodios .vuesax-bold-link-16 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 665px !important;
  left: 1294px !important;
}

.episodios .vuesax-bold-link-16-2 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 665px !important;
  left: 1230px !important;
}

.episodios .vuesax-bold-link-17 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 775px !important;
  left: 1294px !important;
}

.episodios .vuesax-bold-link-17-2 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 775px !important;
  left: 1230px !important;
}

.episodios .vuesax-bold-link-18 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 885px !important;
  left: 1294px !important;
}

.episodios .vuesax-bold-link-18-2 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 885px !important;
  left: 1230px !important;
}

.episodios .vuesax-bold-link-19 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 995px !important;
  left: 1294px !important;
}

.episodios .vuesax-bold-link-19-2 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 995px !important;
  left: 1230px !important;
}

.episodios .vuesax-bold-tick-3 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 445px !important;
  left: 180px !important;
}

.episodios .vuesax-bold-tick-4 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 775px !important;
  left: 180px !important;
}

.episodios .rectangle-20 {
  background-color: #d9d9d9;
  width: 66px;
  height: 61px;
  position: absolute;
  top: 37px;
  left: 33px;
}

.episodios .vuesax-bold-gallery-12-instance {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 56px !important;
  left: 54px !important;
}

.episodios .vuesax-bold-gallery-instance {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 56px !important;
  left: 1350px !important;
}

.episodios .vuesax-bold-gallery-2 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 335px !important;
  left: 276px !important;
}

.episodios .vuesax-bold-gallery-3 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 445px !important;
  left: 276px !important;
}

.episodios .vuesax-bold-gallery-4 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 555px !important;
  left: 276px !important;
}

.episodios .vuesax-bold-gallery-5 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 665px !important;
  left: 276px !important;
}

.episodios .vuesax-bold-gallery-6 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 775px !important;
  left: 276px !important;
}

.episodios .vuesax-bold-gallery-7 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 885px !important;
  left: 276px !important;
}

.episodios .vuesax-bold-gallery-8 {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 995px !important;
  left: 276px !important;
}

.episodios .text-wrapper-164 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 147px;
  left: 156px;
}

.episodios .text-wrapper-165 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 334px;
  left: 408px;
}

.episodios .text-wrapper-166 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 444px;
  left: 408px;
}

.episodios .text-wrapper-167 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 554px;
  left: 408px;
}

.episodios .text-wrapper-168 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 664px;
  left: 408px;
}

.episodios .text-wrapper-169 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 774px;
  left: 408px;
}

.episodios .text-wrapper-170 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 884px;
  left: 408px;
}

.episodios .text-wrapper-171 {
  color: #000;
  letter-spacing: 0;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 994px;
  left: 408px;
}

.episodios .text-wrapper-172 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 334px;
  left: 727px;
}

.episodios .text-wrapper-173 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 444px;
  left: 727px;
}

.episodios .text-wrapper-174 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 554px;
  left: 727px;
}

.episodios .text-wrapper-175 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 664px;
  left: 727px;
}

.episodios .text-wrapper-176 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 774px;
  left: 727px;
}

.episodios .text-wrapper-177 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 884px;
  left: 727px;
}

.episodios .text-wrapper-178 {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 994px;
  left: 727px;
}

.episodios .text-wrapper-179, .episodios .text-wrapper-definitive {
  color: #000;
  letter-spacing: 0;
  opacity: .5;
  font-family: Poppins, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 243px;
  left: 896px;
}

.popup-content {
  background: #fff;
  border: 1px solid #d7d7d7;
  width: 50%;
  margin: auto;
  padding: 5px;
  box-shadow: none !important;
  background-color: #0000 !important;
  border: none !important;
}

[role="tooltip"].popup-content {
  border-radius: 5px;
  width: 200px;
  box-shadow: 0 0 3px #00000029;
}

.popup-overlay {
  background: #00000080;
}

[data-popup="tooltip"].popup-overlay {
  background: none;
}

.popup-arrow {
  filter: drop-shadow(0 -3px 3px #00000029);
  color: #fff;
  stroke-width: 2px;
  stroke: #d7d7d7;
  stroke-dasharray: 30;
  stroke-dashoffset: -54px;
  inset: 0;
}

:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei", "Source Han Sans CN", sans-serif;
}

.upload-main-container {
  overflow: hidden;
}

.upload-main-container, .upload-main-container * {
  box-sizing: border-box;
}

input, select, textarea, button {
  outline: 0;
}

.upload-main-container {
  background: #fff;
  border-radius: 20px;
  flex-flow: column;
  align-items: flex-start;
  gap: 20px;
  width: 600px;
  margin: 0 auto;
  padding: 32px 24px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.upload-section {
  flex-wrap: nowrap;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  width: 552px;
  display: flex;
  position: relative;
}

.upload-text {
  color: #3a3f52;
  font-family: Inter, var(--default-font-family);
  text-align: left;
  white-space: nowrap;
  z-index: 1;
  flex-shrink: 0;
  flex-basis: auto;
  height: 24px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  position: relative;
}

.upload-wrapper {
  z-index: 2;
  background: #ecedf2;
  border-radius: 30px;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
}

.upload-img {
  z-index: 3;
  background: url("cdb7eca4-5663-4739-8a21-027500c8fcf9.18bc529e.png") center / cover no-repeat;
  width: 7.5px;
  height: 7.5px;
  margin: 8.25px 0 0 8.25px;
  position: relative;
}

.upload-group {
  z-index: 4;
  border: 1px dashed #d5d8e2;
  border-radius: 12px;
  flex-flow: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 552px;
  padding: 40px 24px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.upload-title-icon {
  z-index: 5;
  flex-flow: column;
  flex-shrink: 0;
  align-self: stretch;
  align-items: center;
  gap: 16px;
  min-width: 0;
  display: flex;
  position: relative;
}

.upload-paper-upload {
  z-index: 6;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
  overflow: hidden;
}

.upload-iconly-outline-paper {
  z-index: 7;
  width: 22.735px;
  height: 26.487px;
  margin: 2.666px 0 0 4.004px;
  position: relative;
}

.upload-paper-upload-1 {
  z-index: 8;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.upload-combined-shape {
  z-index: 9;
  background: url("8f6f6cd3-a036-44c5-b1a2-b5c579d0c03c.02398db9.png") center / 100% 100% no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.upload-title-subtitle {
  z-index: 10;
  flex-flow: column;
  flex-shrink: 0;
  align-items: center;
  gap: 8px;
  width: 204px;
  display: flex;
  position: relative;
}

.upload-click-select-audio {
  color: #282a37;
  font-family: Inter, var(--default-font-family);
  text-align: left;
  white-space: nowrap;
  z-index: 11;
  flex-shrink: 0;
  flex-basis: auto;
  height: 17px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.943px;
  position: relative;
}

.upload-mp3-files-only {
  color: #515978;
  font-family: Inter, var(--default-font-family);
  text-align: left;
  white-space: nowrap;
  z-index: 12;
  flex-shrink: 0;
  flex-basis: auto;
  height: 17px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.943px;
  position: relative;
}

.upload-line {
  z-index: 13;
  flex-wrap: nowrap;
  flex-shrink: 0;
  align-items: center;
  gap: 10px;
  width: 552px;
  display: flex;
  position: relative;
}

.upload-vector {
  z-index: 14;
  background: url("eeb6d121-3f9d-41ba-8ca7-ca1e2c8056f7.53014b68.png") center / cover no-repeat;
  flex-shrink: 0;
  width: 257px;
  height: 1px;
  position: relative;
}

.upload-or {
  color: #667091;
  font-family: Inter, var(--default-font-family);
  text-align: left;
  white-space: nowrap;
  letter-spacing: .24px;
  z-index: 15;
  flex-shrink: 0;
  flex-basis: auto;
  height: 15px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.523px;
  position: relative;
}

.upload-vector-2 {
  z-index: 16;
  background: url("c733f1e0-47fd-44a4-ad31-8db8cbc16318.53014b68.png") center / cover no-repeat;
  flex-shrink: 0;
  width: 257px;
  height: 1px;
  position: relative;
}

.upload-wrapper-2 {
  z-index: 17;
  flex-flow: column;
  flex-shrink: 0;
  align-items: flex-start;
  gap: 8px;
  width: 536px;
  display: flex;
  position: relative;
}

.upload-text-5 {
  color: #1e1e1e;
  font-family: Inter, var(--default-font-family);
  text-align: left;
  white-space: nowrap;
  z-index: 18;
  flex-shrink: 0;
  flex-basis: auto;
  align-self: stretch;
  min-width: 0;
  height: 22px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  position: relative;
}

.upload-group-3 {
  z-index: 19;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  flex-wrap: nowrap;
  flex-shrink: 0;
  align-self: stretch;
  align-items: center;
  min-width: 240px;
  padding: 12px 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.upload-text-6 {
  color: #1e1e1e;
  font-family: Inter, var(--default-font-family);
  text-align: left;
  white-space: nowrap;
  z-index: 20;
  flex: 1 0 auto;
  height: 6px;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
}

.upload-section-6 {
  z-index: 21;
  flex-flow: column;
  flex-shrink: 0;
  align-items: flex-start;
  gap: 24px;
  width: 552px;
  display: flex;
  position: relative;
  left: 430px;
}

.upload-group-4 {
  flex-wrap: nowrap;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.upload-no-resize {
  resize: none;
  border: none;
  outline: none;
  width: 100%;
  height: 16px;
  overflow: hidden;
}

.upload-btn {
  cursor: pointer;
  z-index: 23;
  background: none;
  border: none;
  flex-wrap: nowrap;
  flex-shrink: 0;
  align-items: flex-start;
  gap: 8px;
  width: 97px;
  display: flex;
  position: relative;
}

.upload-btn-3 {
  z-index: 24;
  background: #b0b5c9;
  border-radius: 8px;
  flex-wrap: nowrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 97px;
  padding: 10px 24px;
  display: flex;
  position: relative;
}

.upload-btn-4 {
  z-index: 24;
  color: #18a0fb;
  background: #e7f5fe;
  border: none;
  border-radius: 8px;
  flex-wrap: nowrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 140px;
  padding: 10px 24px;
  display: flex;
  position: relative;
  top: 5px;
}
/*# sourceMappingURL=index.d17fcbb4.css.map */
