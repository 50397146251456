.website {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.website .div-7 {
  background-color: #ffffff;
  height: 800px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.website .illustration-2 {
  background-color: #f3d083;
  height: 800px;
  left: -1px;
  position: absolute;
  top: 0;
  width: 801px;
}

.website .overlap-30 {
  background-image: url(../../../static/img/path.svg);
  background-size: 100% 100%;
  height: 742px;
  left: 1px;
  position: relative;
  top: 58px;
  width: 747px;
}

.website .path-51 {
  height: 708px;
  left: 0;
  position: absolute;
  top: 34px;
  width: 720px;
}

.website .path-52 {
  height: 252px;
  left: 0;
  position: absolute;
  top: 490px;
  width: 653px;
}

.website .path-53 {
  height: 312px;
  left: 0;
  position: absolute;
  top: 430px;
  width: 527px;
}

.website .oval-4 {
  height: 52px;
  left: 122px;
  position: absolute;
  top: 509px;
  width: 201px;
}

.website .oval-5 {
  height: 35px;
  left: 568px;
  position: absolute;
  top: 544px;
  width: 123px;
}

.website .group-19 {
  height: 302px;
  left: 566px;
  position: absolute;
  top: 273px;
  width: 133px;
}

.website .overlap-group-9 {
  height: 302px;
  position: relative;
}

.website .path-54 {
  height: 24px;
  left: 76px;
  position: absolute;
  top: 278px;
  width: 25px;
}

.website .path-55 {
  height: 65px;
  left: 82px;
  position: absolute;
  top: 214px;
  width: 22px;
}

.website .path-56 {
  height: 17px;
  left: 34px;
  position: absolute;
  top: 271px;
  width: 27px;
}

.website .path-57 {
  height: 61px;
  left: 46px;
  position: absolute;
  top: 212px;
  width: 22px;
}

.website .path-58 {
  height: 55px;
  left: 78px;
  position: absolute;
  top: 0;
  width: 50px;
}

.website .path-59 {
  height: 55px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 58px;
}

.website .path-60 {
  height: 169px;
  left: 31px;
  position: absolute;
  top: 57px;
  width: 97px;
}

.website .shape-4 {
  height: 78px;
  left: 110px;
  position: absolute;
  top: 66px;
  width: 18px;
}

.website .path-61 {
  height: 32px;
  left: 38px;
  position: absolute;
  top: 60px;
  width: 22px;
}

.website .path-62 {
  height: 135px;
  left: 93px;
  position: absolute;
  top: 88px;
  width: 30px;
}

.website .path-63 {
  height: 8px;
  left: 66px;
  position: absolute;
  top: 118px;
  width: 19px;
}

.website .path-64 {
  height: 52px;
  left: 0;
  position: absolute;
  top: 61px;
  width: 52px;
}

.website .path-65 {
  height: 12px;
  left: 35px;
  position: absolute;
  top: 84px;
  width: 10px;
}

.website .path-66 {
  height: 52px;
  left: 0;
  position: absolute;
  top: 61px;
  width: 32px;
}

.website .path-67 {
  height: 56px;
  left: 88px;
  position: absolute;
  top: 95px;
  width: 45px;
}

.website .path-68 {
  height: 45px;
  left: 117px;
  position: absolute;
  top: 95px;
  width: 16px;
}

.website .path-69 {
  height: 21px;
  left: 66px;
  position: absolute;
  top: 48px;
  width: 23px;
}

.website .path-70 {
  height: 44px;
  left: 56px;
  position: absolute;
  top: 15px;
  width: 46px;
}

.website .group-20 {
  height: 415px;
  left: 77px;
  position: absolute;
  top: 38px;
  width: 464px;
}

.website .overlap-31 {
  height: 394px;
  left: 0;
  position: absolute;
  top: 22px;
  width: 464px;
}

.website .overlap-32 {
  height: 394px;
  left: 0;
  position: absolute;
  top: 0;
  width: 464px;
}

.website .overlap-33 {
  height: 354px;
  left: 55px;
  position: absolute;
  top: 40px;
  width: 408px;
}

.website .path-71 {
  height: 229px;
  left: 1px;
  position: absolute;
  top: 124px;
  width: 170px;
}

.website .path-72 {
  height: 229px;
  left: 1px;
  position: absolute;
  top: 125px;
  width: 137px;
}

.website .path-73 {
  height: 123px;
  left: 137px;
  position: absolute;
  top: 174px;
  width: 33px;
}

.website .path-74 {
  height: 34px;
  left: 82px;
  position: absolute;
  top: 141px;
  width: 27px;
}

.website .path-75 {
  height: 76px;
  left: 94px;
  position: absolute;
  top: 168px;
  width: 30px;
}

.website .path-76 {
  height: 106px;
  left: 64px;
  position: absolute;
  top: 138px;
  width: 53px;
}

.website .path-77 {
  height: 122px;
  left: 47px;
  position: absolute;
  top: 124px;
  width: 62px;
}

.website .path-78 {
  height: 108px;
  left: 112px;
  position: absolute;
  top: 137px;
  width: 20px;
}

.website .path-79 {
  height: 135px;
  left: 0;
  position: absolute;
  top: 161px;
  width: 147px;
}

.website .path-80 {
  height: 28px;
  left: 40px;
  position: absolute;
  top: 216px;
  width: 15px;
}

.website .path-81 {
  height: 135px;
  left: 0;
  position: absolute;
  top: 161px;
  width: 87px;
}

.website .path-82 {
  height: 35px;
  left: 131px;
  position: absolute;
  top: 236px;
  width: 17px;
}

.website .path-83 {
  height: 57px;
  left: 166px;
  position: absolute;
  top: 217px;
  width: 26px;
}

.website .path-84 {
  height: 42px;
  left: 131px;
  position: absolute;
  top: 225px;
  width: 69px;
}

.website .path-85 {
  height: 107px;
  left: 18px;
  position: absolute;
  top: 0;
  width: 125px;
}

.website .path-86 {
  height: 32px;
  left: 90px;
  position: absolute;
  top: 128px;
  width: 25px;
}

.website .path-87 {
  height: 108px;
  left: 34px;
  position: absolute;
  top: 40px;
  width: 102px;
}

.website .path-88 {
  height: 19px;
  left: 78px;
  position: absolute;
  top: 128px;
  width: 24px;
}

.website .shape-5 {
  height: 55px;
  left: 47px;
  position: absolute;
  top: 40px;
  width: 82px;
}

.website .path-89 {
  height: 50px;
  left: 54px;
  position: absolute;
  top: 121px;
  width: 38px;
}

.website .path-90 {
  height: 158px;
  left: 148px;
  position: absolute;
  top: 120px;
  width: 147px;
}

.website .oval-6 {
  height: 162px;
  left: 248px;
  position: absolute;
  top: 118px;
  width: 122px;
}

.website .path-91 {
  height: 74px;
  left: 248px;
  position: absolute;
  top: 161px;
  width: 74px;
}

.website .path-92 {
  height: 162px;
  left: 248px;
  position: absolute;
  top: 118px;
  width: 84px;
}

.website .shape-6 {
  height: 165px;
  left: 246px;
  position: absolute;
  top: 116px;
  width: 125px;
}

.website .path-93 {
  height: 34px;
  left: 326px;
  position: absolute;
  top: 128px;
  width: 66px;
}

.website .path-94 {
  height: 21px;
  left: 339px;
  position: absolute;
  top: 191px;
  width: 70px;
}

.website .path-95 {
  height: 54px;
  left: 323px;
  position: absolute;
  top: 229px;
  width: 65px;
}

.website .path-96 {
  height: 40px;
  left: 218px;
  position: absolute;
  top: 0;
  width: 37px;
}

.website .path-97 {
  height: 23px;
  left: 270px;
  position: absolute;
  top: 72px;
  width: 19px;
}

.website .path-98 {
  height: 25px;
  left: 0;
  position: absolute;
  top: 122px;
  width: 56px;
}

.website .path-99 {
  height: 20px;
  left: 28px;
  position: absolute;
  top: 40px;
  width: 20px;
}

.website .path-100 {
  height: 22px;
  left: 149px;
  position: absolute;
  top: 0;
  width: 18px;
}

.website .text-20 {
  height: 91px;
  left: 928px;
  position: absolute;
  top: 197px;
  width: 384px;
}

.website .text-21 {
  height: 91px;
  position: relative;
  width: 388px;
}

.website .get-more-things-done-2 {
  color: #262626;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0.3px;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 384px;
}

.website .search-sourcing-the-2 {
  color: #272727d9;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.26px;
  line-height: 22.9px;
  position: absolute;
  top: 45px;
  width: 384px;
}

.website .form-log-in-3 {
  display: flex !important;
  height: 395px !important;
  left: 878px !important;
  min-width: unset !important;
  position: absolute !important;
  top: 329px !important;
  width: 444px !important;
}

.website .form-log-in-4 {
  overflow: unset !important;
}
