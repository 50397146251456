.index {
  background-color: #121316;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.index .div-2 {
  background-color: var(--dark);
  height: 5644px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.index .footer {
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 50px;
  height: 426px;
  justify-content: space-around;
  left: -41px;
  padding: 0px 80px;
  position: absolute;
  top: 5321px;
  width: 1440px;
}

.index .frame-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 25px;
  position: relative;
}

.index .logo {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  justify-content: center;
  position: relative;
}

.index .adaptive-audio-mic {
  height: 40px;
  position: relative;
  width: 40px;
}

.index .text-wrapper-8 {
  color: var(--light);
  font-family: "Poppins", Helvetica;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.index .element-linearity-gmb {
  color: var(--light);
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: relative;
  text-align: center;
  width: fit-content;
}

.index .all-inclusive {
  align-items: center;
  background-color: var(--light);
  border-radius: 15px;
  box-shadow: 4px 4px 4px #00000026;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  height: 54px;
  justify-content: center;
  left: 1372px;
  opacity: 0.62;
  overflow-y: scroll;
  padding: 15px;
  position: absolute;
  top: 5262px;
}

.index .arrow-up {
  height: 34px !important;
  position: relative !important;
  width: 24px !important;
}

.index .connection {
  height: 629px;
  left: 195px;
  position: absolute;
  top: 4602px;
  width: 1049px;
}

.index .text-4 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  left: 133px;
  padding: 0px 30px;
  position: absolute;
  top: 0;
 
  width: 778px;
}

.audio-player-overlay {
  position: fixed; /* ou absolute, dependendo do comportamento desejado */
  bottom: 30px; /* Ajuste conforme necessário */
  right: 200px; /* Ajuste conforme necessário */
  z-index: 1000; /* Certifique-se de que o z-index seja alto o suficiente para sobrepor outros elementos */
  background-color: rgb(255, 255, 255); /* Opcional: Adicione um fundo para melhor visibilidade */
  padding: 10px; /* Opcional: Adicione padding para espaçamento interno */
  border-radius: 5px; /* Opcional: Adicione bordas arredondadas */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Opcional: Adicione sombra para destaque */
  width: 1500px; /* Ajuste conforme necessário */
  
}

.index .text-wrapper-9 {
  color: var(--light);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 20px;
  position: relative;
  width: fit-content;
  
}

.index .text-wrapper-99 {
  color: var(--light);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
  transform: rotate(180deg);
}


.index .group {
  height: 438px;
  left: 0;
  position: absolute;
  top: 191px;
  width: 1049px;
}

.index .logo-2 {
  align-items: center;
  background-color: var(--primary);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 220px;
  justify-content: center;
  left: 390px;
  padding: 30px;
  position: absolute;
  top: 48px;
  width: 216px;
}

.index .img {
  height: 75px;
  margin-top: -5.5px;
  position: relative;
  width: 75px;
}

.index .text-wrapper-10 {
  color: var(--light);
  font-family: "Poppins", Helvetica;
  font-size: 28px;
  font-weight: 600;
  height: 86px;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -4.5px;
  margin-left: -23.5px;
  margin-right: -23.5px;
  position: relative;
  text-align: center;
  width: 203px;
}

.index .all-inclusive-2 {
  height: 54px;
  left: 133px;
  position: absolute;
  top: 13px;
  width: 54px;
}

.index .circles-wrapper {
  align-items: center;
  background-color: var(--primary);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 80px;
  justify-content: center;
  left: 888px;
  padding: 15px;
  position: absolute;
  top: 0;
  width: 80px;
}

.index .img-2 {
  height: 35px;
  position: relative;
  width: 35px;
}

.index .contactless-wrapper {
  align-items: center;
  background-color: var(--primary);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 80px;
  justify-content: center;
  left: 197px;
  padding: 15px;
  position: absolute;
  top: 149px;
  width: 80px;
}

.index .img-wrapper {
  align-items: center;
  background-color: var(--primary);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 52px;
  justify-content: center;
  left: 766px;
  padding: 15px;
  position: absolute;
  top: 110px;
  width: 52px;
}

.index .img-3 {
  height: 20px;
  position: relative;
  width: 20px;
}

.index .all-inclusive-3 {
  height: 62px;
  left: 849px;
  position: absolute;
  top: 268px;
  width: 62px;
}

.index .all-inclusive-4 {
  height: 62px;
  left: 262px;
  position: absolute;
  top: 301px;
  width: 62px;
}

.index .editor-choice-wrapper {
  align-items: center;
  background-color: var(--dark-secondary);
  border-radius: 15px;
  box-shadow: 4px 4px 4px #00000026;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 83px;
  justify-content: center;
  left: 699px;
  padding: 15px;
  position: absolute;
  top: 355px;
  width: 83px;
}

.index .all-inclusive-5 {
  align-items: center;
  background-color: var(--dark-secondary);
  border-radius: 15px;
  box-shadow: 4px 4px 4px #00000026;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 83px;
  justify-content: center;
  left: 966px;
  padding: 15px;
  position: absolute;
  top: 154px;
  width: 83px;
}

.index .all-inclusive-6 {
  align-items: center;
  background-color: var(--dark-secondary);
  border-radius: 15px;
  box-shadow: 4px 4px 4px #00000026;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 58px;
  justify-content: center;
  left: 0;
  padding: 15px;
  position: absolute;
  top: 272px;
  width: 58px;
}

.index .editor-choice {
  height: 25px;
  position: relative;
  width: 25px;
}

.index .host {
  height: 982px;
  left: 85px;
  position: absolute;
  top: 3393px;
  transform: rotate(180deg);
  width: 1025px;
}

.index .text-5 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  left: 0;
  padding: 0px 30px;
  position: absolute;
  top: 798px;
  width: 777.75px;
}

.index .frame-3 {
  align-items: center;
  background-color: var(--primary);
  border-radius: 100px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 8px 30px;
  position: relative;
  transform: rotate(180deg);
}

.index .text-wrapper-11 {
  color: var(--light);
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.index .card {
  align-items: center;
  background-color: var(--dark-secondary);
  border-radius: 20px;
  box-shadow: 5px 7px 17px #00000012;
  display: inline-flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  left: auto;
  padding: 15px;
  position: absolute;
  right: 100;
  
  width: 1000;
  
}

.index .image {
  height: 340px;
  object-fit: cover;
  position: relative;
  transform: rotate(-180deg);
  width: 354px;
}

.index .frame-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  padding: 20px 0px;
  position: relative;
  transform: rotate(180deg);
}

.index .text-wrapper-12 {
  color: var(--light);
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 347px;
}

.index .text-wrapper-13 {
  color: var(--light);
  font-family: "Open Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: auto;
}

.index .frame-5 {
  align-items: center;
  background-color: var(--primary);
  border-radius: 100px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 12px 30px;
  position: relative;
  transform: rotate(180deg);
}

.index .text-wrapper-14 {
  color: var(--light);
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.index .lates-episodes {
  height: 1007px;
  left: 80px;
  position: absolute;
  top: 2054px;
  width: 1282px;
  
}

.index .frame-6 {
  align-items: flex-start;
  display: inline-flex;
  gap: 30px;
  left: 0;
  position: absolute;
  top: 248px;
  
}

.index .frame-7 {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  


}
.scrollable-container {
  max-height: 1400px;
  
  overflow-y: scroll;
  overflow-x: hidden;
}

.index .card-2 {
  align-items: flex-start;
  background-color: var(--dark-secondary);
  border-radius: 20px;
  box-shadow: 4px 7px 17px #00000012;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 43px;
  padding: 30px 40px;
  position: relative;
  width: 1000px;
  
}

.index .img-4 {
  flex: 0 0 auto;
  position: relative;
}

.index .text-6 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.index .text-wrapper-15 {
  
  color: var(--light);
  font-family: "Poppins", Helvetica;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
  
}

.index .card-body {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 153px;
  justify-content: center;
  position: relative;
}

.index .frame-8 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  
}

.index .frame-9 {
  flex: 0 0 auto;
  position: relative;
  width: 303px;
}

.index .frame-10 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.index .text-wrapper-16 {
  color: var(--light);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.index .play-arrow {
  display: block;
  height: 60px;
  position: relative;
  width: 60px;
  right: -120px;
}

.index .frame-11 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 41px;
  position: relative;
}

.index .frame-12 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 30px 30px;
  overflow-x: scroll;
  position: relative;
  width: 400px;
}

.index .frame-13 {
  align-items: flex-start;
  background-color: var(--dark-secondary);
  border-radius: 20px;
  box-shadow: 2px 4px 17px #0000000d;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 21px;
  padding: 20px;
  position: relative;
}

.index .images {
  background-color: #c4c4c4;
  border-radius: 15px;
  height: 144px;
  position: relative;
  width: 144px;
}

.index .group-2 {
  height: 71px;
  margin-right: -4px;
  position: relative;
  width: 148px;
}

.index .text-wrapper-17 {
  color: var(--light);
  font-family: "Open Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.32px;
  line-height: 32px;
  position: absolute;
  top: 39px;
  width: 144px;
}

.index .text-wrapper-18 {
  color: var(--light);
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.index .frame-14 {
  align-items: center;
  background-color: var(--primary);
  border-radius: 100px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 10px 30px;
  position: relative;
}

.index .text-wrapper-19 {
  color: var(--light);
  font-family: "Poppins", Helvetica;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.index .frame-15 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  left: 0;
  padding: 0px 30px;
  position: absolute;
  top: 0;
  width: 777.75px;
}

.index .frame-16 {
  align-items: center;
  background-color: var(--primary);
  border-radius: 100px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 8px 30px;
  position: relative;
}

.index .text-wrapper-20 {
  color: var(--light);
  font-family: "Poppins", Helvetica;
  font-size: 42px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 607px;
}

.index .about-us {
  height: 529px;
  left: 116px;
  position: absolute;
  top: 1193px;
  width: 1207px;
}

.index .images-2 {
  height: 529px;
  left: 0;
  position: absolute;
  top: 0;
  width: 464px;
}

.index .overlap-group-2 {
  height: 529px;
  position: relative;
}

.index .images-3 {
  background-color: #c4c4c4;
  border-radius: 20px;
  height: 426px;
  left: 0;
  position: absolute;
  top: 0;
  width: 341px;
}

.index .images-4 {
  background-color: #c4c4c4;
  border: 10px solid;
  border-color: var(--dark);
  border-radius: 20px;
  height: 333px;
  left: 187px;
  position: absolute;
  top: 196px;
  width: 277px;
}

.index .frame-17 {
  align-items: flex-start;
  background-color: var(--dark-secondary);
  border-radius: 15px;
  box-shadow: 4px 7px 10px #00000012;
  display: inline-flex;
  gap: 26px;
  left: 58px;
  padding: 25px;
  position: absolute;
  top: 363px;
}

.index .frame-18 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3px;
  position: relative;
}

.index .text-wrapper-21 {
  color: var(--light);
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.index .text-wrapper-22 {
  color: var(--light-secondary);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.index .text-7 {
  height: 509px;
  left: 600px;
  position: absolute;
  top: 0;
  width: 611px;
}

.index .text-wrapper-23 {
  color: var(--light);
  font-family: "Poppins", Helvetica;
  font-size: 42px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 58px;
  width: 607px;
}

.index .frame-19 {
  align-items: center;
  background-color: var(--primary);
  border-radius: 100px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  padding: 8px 30px;
  position: absolute;
  top: 0;
}

.index .text-wrapper-24 {
  color: var(--light);
  font-family: "Poppins", Helvetica;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.index .text-wrapper-25 {
  color: var(--light);
  font-family: "Open Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 5px;
  letter-spacing: 0.32px;
  line-height: 32px;
  position: absolute;
  top: 221px;
  width: 584px;
}

.index .overlap {
  height: 1444px;
  left: 80px;
  position: absolute;
  top: -419px;
  width: 1669px;
}

.index .header {
  background-color: transparent;
  height: 1444px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1669px;
}

.index .overlap-2 {
  height: 1212px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1667px;
}

.index .ellipse {
  background-color: var(--primary);
  border-radius: 464px;
  height: 928px;
  left: 739px;
  position: absolute;
  top: 0;
  width: 928px;
}

.index .images-5 {
  background-color: #060f16;
  border-radius: 0px 0px 40px 40px;
  height: 748px;
  left: 804px;
  position: absolute;
  top: 419px;
  width: 476px;
}

.index .text-8 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 42px;
  left: 0;
  position: absolute;
  top: 634px;
}

.index .group-3 {
  height: 252px;
  margin-right: -2px;
  position: relative;
  width: 751px;
}

.index .overlap-group-3 {
  height: 252px;
  position: relative;
  width: 749px;
}

.index .rectangle {
  background-color: var(--primary);
  box-shadow: 0px 4px 4px #00000040;
  height: 81px;
  left: 0;
  position: absolute;
  top: 171px;
  width: 347px;
}

.index .text-wrapper-26 {
  color: var(--light);
  font-family: "Poppins", Helvetica;
  font-size: 56px;
  font-weight: 700;
  left: 10px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 739px;
}

.index .text-wrapper-27 {
  color: var(--light);
  font-family: "Open Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: 32px;
  position: relative;
  width: 643px;
}

.index .vector-2 {
  height: 124px;
  left: 408px;
  position: absolute;
  top: 1088px;
  width: 372px;
}

.index .footballer {
  height: 748px;
  left: 804px;
  position: absolute;
  top: 419px;
  width: 475px;
}

.index .frame-20 {
  align-items: flex-start;
  background-color: var(--dark-secondary);
  border-radius: 15px;
  box-shadow: 4px 7px 10px #00000012;
  display: inline-flex;
  gap: 26px;
  left: 578px;
  padding: 25px;
  position: absolute;
  top: 1023px;
}

.index .frame-21 {
  display: block;
  height: 63px;
  left: 0;
  position: absolute;
  top: 1104px;
  width: 203px;
}

.index .text-wrapper-28 {
  color: var(--light);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.8;
  position: absolute;
  text-align: center;
  top: 0;
}

.index .text-9 {
  height: 88px;
  left: 351px;
  position: absolute;
  top: 1238px;
  width: 432px;
}

.index .text-wrapper-29 {
  color: var(--light);
  font-family: "Open Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.32px;
  line-height: 32px;
  position: absolute;
  top: 56px;
  width: 428px;
}

.index .text-10 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 1238px;
}

.index .text-wrapper-30 {
  color: var(--primary);
  font-family: "Poppins", Helvetica;
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.index .text-wrapper-31 {
  color: var(--light-secondary);
  font-family: "Poppins", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.index .theme {
  height: 206px;
  left: 813px;
  position: absolute;
  top: 1238px;
  width: 460px;
}

.index .frame-22 {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 15px 20px;
  left: 0;
  position: absolute;
  top: 56px;
  width: 458px;
}

.index .frame-23 {
  align-items: center;
  background-color: var(--dark-secondary);
  border-radius: 100px;
  box-shadow: 4px 4px 4px #00000012;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 8px 20px;
  position: relative;
}

.index .menu {
  align-items: center;
  display: inline-flex;
  gap: 96px;
  left: 0;
  position: absolute;
  top: 483px;
}

.index .navbar {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 35px;
  position: relative;
}

.index .text-wrapper-32 {
  color: var(--light);
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  opacity: 0.8;
  position: relative;
  text-align: center;
  width: fit-content;
}

.index .text-wrapper-33 {
  color: var(--light);
  display: block;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  opacity: 0.8;
  position: relative;
  text-align: center;
  width: fit-content;
}

