.login {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.login .div-4 {
  background-color: #ffffff;
  height: 800px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.login .illustration {
  background-color: #f3d083;
  height: 800px;
  left: -1px;
  position: absolute;
  top: 0;
  width: 801px;
}

.login .overlap-12 {
  background-image: url(../../../static/img/path.svg);
  background-size: 100% 100%;
  height: 742px;
  left: 1px;
  position: relative;
  top: 58px;
  width: 747px;
}

.login .path {
  height: 708px;
  left: 0;
  position: absolute;
  top: 34px;
  width: 720px;
}

.login .path-2 {
  height: 252px;
  left: 0;
  position: absolute;
  top: 490px;
  width: 653px;
}

.login .path-3 {
  height: 312px;
  left: 0;
  position: absolute;
  top: 430px;
  width: 527px;
}

.login .oval {
  height: 52px;
  left: 122px;
  position: absolute;
  top: 509px;
  width: 201px;
}

.login .oval-2 {
  height: 35px;
  left: 568px;
  position: absolute;
  top: 544px;
  width: 123px;
}

.login .group-8 {
  height: 302px;
  left: 566px;
  position: absolute;
  top: 273px;
  width: 133px;
}

.login .overlap-group-5 {
  height: 302px;
  position: relative;
}

.login .path-4 {
  height: 24px;
  left: 76px;
  position: absolute;
  top: 278px;
  width: 25px;
}

.login .path-5 {
  height: 65px;
  left: 82px;
  position: absolute;
  top: 214px;
  width: 22px;
}

.login .path-6 {
  height: 17px;
  left: 34px;
  position: absolute;
  top: 271px;
  width: 27px;
}

.login .path-7 {
  height: 61px;
  left: 46px;
  position: absolute;
  top: 212px;
  width: 22px;
}

.login .path-8 {
  height: 55px;
  left: 78px;
  position: absolute;
  top: 0;
  width: 50px;
}

.login .path-9 {
  height: 55px;
  left: 51px;
  position: absolute;
  top: 0;
  width: 58px;
}

.login .path-10 {
  height: 169px;
  left: 31px;
  position: absolute;
  top: 57px;
  width: 97px;
}

.login .shape {
  height: 78px;
  left: 110px;
  position: absolute;
  top: 66px;
  width: 18px;
}

.login .path-11 {
  height: 32px;
  left: 38px;
  position: absolute;
  top: 60px;
  width: 22px;
}

.login .path-12 {
  height: 135px;
  left: 93px;
  position: absolute;
  top: 88px;
  width: 30px;
}

.login .path-13 {
  height: 8px;
  left: 66px;
  position: absolute;
  top: 118px;
  width: 19px;
}

.login .path-14 {
  height: 52px;
  left: 0;
  position: absolute;
  top: 61px;
  width: 52px;
}

.login .path-15 {
  height: 12px;
  left: 35px;
  position: absolute;
  top: 84px;
  width: 10px;
}

.login .path-16 {
  height: 52px;
  left: 0;
  position: absolute;
  top: 61px;
  width: 32px;
}

.login .path-17 {
  height: 56px;
  left: 88px;
  position: absolute;
  top: 95px;
  width: 45px;
}

.login .path-18 {
  height: 45px;
  left: 117px;
  position: absolute;
  top: 95px;
  width: 16px;
}

.login .path-19 {
  height: 21px;
  left: 66px;
  position: absolute;
  top: 48px;
  width: 23px;
}

.login .path-20 {
  height: 44px;
  left: 56px;
  position: absolute;
  top: 15px;
  width: 46px;
}

.login .group-9 {
  height: 415px;
  left: 77px;
  position: absolute;
  top: 38px;
  width: 464px;
}

.login .overlap-13 {
  height: 394px;
  left: 0;
  position: absolute;
  top: 22px;
  width: 464px;
}

.login .overlap-14 {
  height: 394px;
  left: 0;
  position: absolute;
  top: 0;
  width: 464px;
}

.login .overlap-15 {
  height: 354px;
  left: 55px;
  position: absolute;
  top: 40px;
  width: 408px;
}

.login .path-21 {
  height: 229px;
  left: 1px;
  position: absolute;
  top: 124px;
  width: 170px;
}

.login .path-22 {
  height: 229px;
  left: 1px;
  position: absolute;
  top: 125px;
  width: 137px;
}

.login .path-23 {
  height: 123px;
  left: 137px;
  position: absolute;
  top: 174px;
  width: 33px;
}

.login .path-24 {
  height: 34px;
  left: 82px;
  position: absolute;
  top: 141px;
  width: 27px;
}

.login .path-25 {
  height: 76px;
  left: 94px;
  position: absolute;
  top: 168px;
  width: 30px;
}

.login .path-26 {
  height: 106px;
  left: 64px;
  position: absolute;
  top: 138px;
  width: 53px;
}

.login .path-27 {
  height: 122px;
  left: 47px;
  position: absolute;
  top: 124px;
  width: 62px;
}

.login .path-28 {
  height: 108px;
  left: 112px;
  position: absolute;
  top: 137px;
  width: 20px;
}

.login .path-29 {
  height: 135px;
  left: 0;
  position: absolute;
  top: 161px;
  width: 147px;
}

.login .path-30 {
  height: 28px;
  left: 40px;
  position: absolute;
  top: 216px;
  width: 15px;
}

.login .path-31 {
  height: 135px;
  left: 0;
  position: absolute;
  top: 161px;
  width: 87px;
}

.login .path-32 {
  height: 35px;
  left: 131px;
  position: absolute;
  top: 236px;
  width: 17px;
}

.login .path-33 {
  height: 57px;
  left: 166px;
  position: absolute;
  top: 217px;
  width: 26px;
}

.login .path-34 {
  height: 42px;
  left: 131px;
  position: absolute;
  top: 225px;
  width: 69px;
}

.login .path-35 {
  height: 107px;
  left: 18px;
  position: absolute;
  top: 0;
  width: 125px;
}

.login .path-36 {
  height: 32px;
  left: 90px;
  position: absolute;
  top: 128px;
  width: 25px;
}

.login .path-37 {
  height: 108px;
  left: 34px;
  position: absolute;
  top: 40px;
  width: 102px;
}

.login .path-38 {
  height: 19px;
  left: 78px;
  position: absolute;
  top: 128px;
  width: 24px;
}

.login .shape-2 {
  height: 55px;
  left: 47px;
  position: absolute;
  top: 40px;
  width: 82px;
}

.login .path-39 {
  height: 50px;
  left: 54px;
  position: absolute;
  top: 121px;
  width: 38px;
}

.login .path-40 {
  height: 158px;
  left: 148px;
  position: absolute;
  top: 120px;
  width: 147px;
}

.login .oval-3 {
  height: 162px;
  left: 248px;
  position: absolute;
  top: 118px;
  width: 122px;
}

.login .path-41 {
  height: 74px;
  left: 248px;
  position: absolute;
  top: 161px;
  width: 74px;
}

.login .path-42 {
  height: 162px;
  left: 248px;
  position: absolute;
  top: 118px;
  width: 84px;
}

.login .shape-3 {
  height: 165px;
  left: 246px;
  position: absolute;
  top: 116px;
  width: 125px;
}

.login .path-43 {
  height: 34px;
  left: 326px;
  position: absolute;
  top: 128px;
  width: 66px;
}

.login .path-44 {
  height: 21px;
  left: 339px;
  position: absolute;
  top: 191px;
  width: 70px;
}

.login .path-45 {
  height: 54px;
  left: 323px;
  position: absolute;
  top: 229px;
  width: 65px;
}

.login .path-46 {
  height: 40px;
  left: 218px;
  position: absolute;
  top: 0;
  width: 37px;
}

.login .path-47 {
  height: 23px;
  left: 270px;
  position: absolute;
  top: 72px;
  width: 19px;
}

.login .path-48 {
  height: 25px;
  left: 0;
  position: absolute;
  top: 122px;
  width: 56px;
}

.login .path-49 {
  height: 20px;
  left: 28px;
  position: absolute;
  top: 40px;
  width: 20px;
}

.login .path-50 {
  height: 22px;
  left: 149px;
  position: absolute;
  top: 0;
  width: 18px;
}

.login .text-11 {
  height: 91px;
  left: 928px;
  position: absolute;
  top: 197px;
  width: 384px;
}

.login .text-12 {
  height: 91px;
  position: relative;
  width: 388px;
}

.login .get-more-things-done {
  color: #262626;
  font-family: "Montserrat", Helvetica;
  font-size: 24px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0.3px;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 384px;
}

.login .search-sourcing-the {
  color: #272727d9;
  font-family: "Montserrat", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.26px;
  line-height: 22.9px;
  position: absolute;
  top: 45px;
  width: 384px;
}

.login .form-log-in-instance {
  display: flex !important;
  height: 395px !important;
  left: 878px !important;
  min-width: unset !important;
  position: absolute !important;
  top: 329px !important;
  width: 444px !important;
}

.login .form-log-in-2 {
  overflow: unset !important;
}

.login .box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 8px;
  position: relative;
  width: 387px;
  height: 117px;
  margin: 54.25px 0 0 4px;
  z-index: 16;
  right: -900px;
  top: 400px;
}
.login .text-3 {
  align-self: stretch;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  min-width: 0;
  height: 22px;
  color: #1e1e1e;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  white-space: nowrap;
  z-index: 17;
  right: -700px;
  top: 100px;
}
.login .input {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  min-width: 240px;
  padding: 12px 16px 12px 16px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  z-index: 18;
  overflow: hidden;
  border-radius: 8px;
}
.login .value {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 16px;
  color: #1e1e1e;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
  z-index: 19;
}
.login .button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 8px;
  position: relative;
  width: 396px;
  margin: 12px 0 0 0;
  padding: 12px 12px 12px 12px;
  cursor: pointer;
  background: #2c2c2c;
  border: 1px solid #2c2c2c;
  z-index: 14;
  overflow: hidden;
  border-radius: 8px;
  right: -900px;
  top: 430px;
}
.login .button-enter {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 16px;
  color: #f5f5f5;
  font-family: Inter, var(--default-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  white-space: nowrap;
  z-index: 15;
}
