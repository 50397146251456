.button-group {
  display: flex;
  gap: var(--size-space-400);
  position: relative;
  width: 240px;
}

.button-group .class {
  display: flex !important;
  flex: 1 !important;
  flex-grow: 1 !important;
}

.button-group .class-2 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.button-group .class-3 {
  flex: 0 0 auto !important;
}

.button-group.end {
  align-items: center;
  justify-content: flex-end;
}

.button-group.justify {
  align-items: center;
}

.button-group.center {
  align-items: center;
  justify-content: center;
}

.button-group.start {
  align-items: center;
}

.button-group.stack {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
