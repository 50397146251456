.playlists {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.playlists .div-3 {
  background-color: #ffffff;
  height: 1024px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.playlists .vuesax-bold-gallery-wrapper {
  background-color: #d9d9d9;
  border-radius: 10px;
  height: 52px;
  left: 1336px;
  position: absolute;
  top: 42px;
  width: 52px;
}

.playlists .icon-instance-node {
  height: 24px !important;
  left: 14px !important;
  position: absolute !important;
  top: 14px !important;
  width: 24px !important;
}

.playlists .group-wrapper {
  height: 72px;
  left: 156px;
  position: absolute;
  top: 32px;
  width: 224px;
}

.playlists .group-4 {
  height: 72px;
  position: relative;
  width: 228px;
}

.playlists .text-wrapper-34 {
  color: #3e435d;
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.playlists .text-wrapper-35 {
  color: #ada7a7;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 48px;
}

.playlists .group-5 {
  height: 52px;
  left: 964px;
  position: absolute;
  top: 42px;
  width: 356px;
}

.playlists .overlap-group-4 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #eaeaea;
  border-radius: 8px;
  height: 52px;
  position: relative;
  width: 354px;
}

.playlists .vuesax-linear-search {
  height: 24px !important;
  left: 19px !important;
  position: absolute !important;
  top: 13px !important;
  width: 24px !important;
}

.playlists .text-wrapper-36 {
  color: #ada7a7;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 59px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 13px;
}

.playlists .vector-3 {
  height: 1024px;
  left: 116px;
  position: absolute;
  top: 0;
  width: 1px;
}

.playlists .group-6 {
  height: 784px;
  left: 32px;
  position: absolute;
  top: 208px;
  width: 54px;
}

.playlists .vuesax-bold-logout-wrapper {
  background-color: #18a0fb;
  border-radius: 8px;
  display: block;
  height: 52px;
  left: 0;
  position: absolute;
  top: 732px;
  width: 52px;
}

.playlists .text-wrapper-37 {
  color: #000000;
  font-family: "DM Sans", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 8px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.3;
  position: absolute;
  top: 0;
}

.playlists .vuesax-bold-element {
  height: 24px !important;
  left: 14px !important;
  position: absolute !important;
  top: 38px !important;
  width: 24px !important;
}

.playlists .vuesax-bold-discover {
  height: 24px !important;
  left: 14px !important;
  position: absolute !important;
  top: 94px !important;
  width: 24px !important;
}

.playlists .overlap-wrapper {
  display: block;
  height: 52px;
  left: 1210px;
  position: absolute;
  top: 136px;
  width: 154px;
}

.playlists .overlap-3 {
  border-radius: 8px;
  height: 52px;
  position: relative;
  width: 152px;
}

.playlists .rectangle-2 {
  background-color: #18a0fb;
  border-radius: 8px;
  height: 52px;
  left: 0;
  opacity: 0.1;
  position: absolute;
  top: 0;
  width: 152px;
}

.playlists .text-wrapper-38 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 20px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 14px;
}

.playlists .group-7 {
  height: 52px;
  left: 999px;
  position: absolute;
  top: 136px;
  width: 196px;
}

.playlists .overlap-4 {
  border-radius: 8px;
  height: 52px;
  position: relative;
  width: 194px;
}

.playlists .rectangle-3 {
  background-color: #ff0000;
  border-radius: 8px;
  height: 52px;
  left: 0;
  opacity: 0.1;
  position: absolute;
  top: 0;
  width: 194px;
}

.playlists .text-wrapper-39 {
  color: #fb1818;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 12px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 14px;
}

.playlists .text-wrapper-40 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 156px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 147px;
}

.playlists .overlap-5 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #eaeaea;
  border-radius: 8px;
  height: 835px;
  left: 156px;
  position: absolute;
  top: 220px;
  width: 1244px;
}

.playlists .vuesax-linear-minus-square {
  background-image: url(../../../static/img/vuesax-linear-minus-square-2.svg) !important;
  left: 23px !important;
  position: absolute !important;
  top: 23px !important;
}

.playlists .vuesax-linear-minus-instance {
  background-image: url(../../../static/img/vuesax-linear-minus-square-2.svg) !important;
  left: 23px !important;
  position: absolute !important;
  top: 114px !important;
}

.playlists .vuesax-linear-minus-square-instance {
  background-image: url(../../../static/img/vuesax-linear-minus-square-2.svg) !important;
  left: 23px !important;
  position: absolute !important;
  top: 334px !important;
}

.playlists .vuesax-linear-minus-2 {
  background-image: url(../../../static/img/vuesax-linear-minus-square-2.svg) !important;
  left: 23px !important;
  position: absolute !important;
  top: 444px !important;
}

.playlists .vuesax-linear-minus-3 {
  background-image: url(../../../static/img/vuesax-linear-minus-square-2.svg) !important;
  left: 23px !important;
  position: absolute !important;
  top: 664px !important;
}

.playlists .vuesax-linear-minus-4 {
  background-image: url(../../../static/img/vuesax-linear-minus-square-2.svg) !important;
  left: 23px !important;
  position: absolute !important;
  top: 774px !important;
}

.playlists .text-wrapper-41 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 71px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 22px;
}

.playlists .text-wrapper-42 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 251px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 22px;
}

.playlists .text-wrapper-43 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 251px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 113px;
}

.playlists .text-wrapper-44 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 251px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 223px;
}

.playlists .text-wrapper-45 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 251px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 333px;
}

.playlists .text-wrapper-46 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 251px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 443px;
}

.playlists .text-wrapper-47 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 251px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 553px;
}

.playlists .text-wrapper-48 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 251px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 663px;
}

.playlists .text-wrapper-49 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 251px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 773px;
}

.playlists .text-wrapper-50 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 570px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 113px;
}

.playlists .text-wrapper-51 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 570px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 223px;
}

.playlists .text-wrapper-52 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 570px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 333px;
}

.playlists .text-wrapper-53 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 570px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 443px;
}

.playlists .text-wrapper-54 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 570px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 553px;
}

.playlists .text-wrapper-55 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 570px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 663px;
}

.playlists .text-wrapper-56 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 570px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 773px;
}

.playlists .text-wrapper-57 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 739px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 113px;
}

.playlists .text-wrapper-58 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 739px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 223px;
}

.playlists .text-wrapper-59 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 739px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 333px;
}

.playlists .text-wrapper-60 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 739px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 443px;
}

.playlists .text-wrapper-61 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 739px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 553px;
}

.playlists .text-wrapper-62 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 739px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 663px;
}

.playlists .text-wrapper-63 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 739px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 773px;
}

.playlists .text-wrapper-64 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 959px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 113px;
}

.playlists .text-wrapper-65 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 959px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 223px;
}

.playlists .text-wrapper-66 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 959px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 333px;
}

.playlists .text-wrapper-67 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 959px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 443px;
}

.playlists .text-wrapper-68 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 959px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 553px;
}

.playlists .text-wrapper-69 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 959px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 663px;
}

.playlists .text-wrapper-70 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 959px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 773px;
}

.playlists .text-wrapper-71 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 570px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 22px;
}

.playlists .text-wrapper-72 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 739px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 22px;
}

.playlists .text-wrapper-73 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 959px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 22px;
}

.playlists .vector-4 {
  height: 1px;
  left: 23px;
  object-fit: cover;
  position: absolute;
  top: 70px;
  width: 1196px;
}

.playlists .vector-5 {
  height: 1px;
  left: 23px;
  object-fit: cover;
  position: absolute;
  top: 180px;
  width: 1196px;
}

.playlists .vector-6 {
  height: 1px;
  left: 23px;
  object-fit: cover;
  position: absolute;
  top: 290px;
  width: 1196px;
}

.playlists .vector-7 {
  height: 1px;
  left: 23px;
  object-fit: cover;
  position: absolute;
  top: 400px;
  width: 1196px;
}

.playlists .vector-8 {
  height: 1px;
  left: 23px;
  object-fit: cover;
  position: absolute;
  top: 510px;
  width: 1196px;
}

.playlists .vector-9 {
  height: 1px;
  left: 23px;
  object-fit: cover;
  position: absolute;
  top: 620px;
  width: 1196px;
}

.playlists .vector-10 {
  height: 1px;
  left: 23px;
  object-fit: cover;
  position: absolute;
  top: 730px;
  width: 1196px;
}

.playlists .vuesax-bold-gallery-13-wrapper {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 70px;
  left: 72px;
  position: absolute;
  top: 92px;
  width: 120px;
}

.playlists .vuesax-bold-gallery {
  height: 24px !important;
  left: 48px !important;
  position: absolute !important;
  top: 23px !important;
  width: 24px !important;
}

.playlists .vuesax-bold-gallery-12-wrapper {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 70px;
  left: 72px;
  position: absolute;
  top: 202px;
  width: 120px;
}

.playlists .overlap-6 {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 70px;
  left: 72px;
  position: absolute;
  top: 312px;
  width: 120px;
}

.playlists .overlap-7 {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 70px;
  left: 72px;
  position: absolute;
  top: 422px;
  width: 120px;
}

.playlists .overlap-8 {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 70px;
  left: 72px;
  position: absolute;
  top: 532px;
  width: 120px;
}

.playlists .overlap-9 {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 70px;
  left: 72px;
  position: absolute;
  top: 642px;
  width: 120px;
}

.playlists .overlap-10 {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 70px;
  left: 72px;
  position: absolute;
  top: 752px;
  width: 120px;
}

.playlists .vuesax-bold-link {
  height: 24px !important;
  left: 1137px !important;
  position: absolute !important;
  top: 114px !important;
  width: 24px !important;
}

.playlists .vuesax-bold-link-square-1 {
  height: 24px !important;
  left: 1137px !important;
  position: absolute !important;
  top: 224px !important;
  width: 24px !important;
}

.playlists .vuesax-bold-link-square-1-instance {
  height: 24px !important;
  left: 1137px !important;
  position: absolute !important;
  top: 334px !important;
  width: 24px !important;
}

.playlists .vuesax-bold-link-2 {
  height: 24px !important;
  left: 1137px !important;
  position: absolute !important;
  top: 444px !important;
  width: 24px !important;
}

.playlists .vuesax-bold-link-3 {
  height: 24px !important;
  left: 1137px !important;
  position: absolute !important;
  top: 554px !important;
  width: 24px !important;
}

.playlists .vuesax-bold-link-4 {
  height: 24px !important;
  left: 1137px !important;
  position: absolute !important;
  top: 664px !important;
  width: 24px !important;
}

.playlists .vuesax-bold-link-5 {
  height: 24px !important;
  left: 1137px !important;
  position: absolute !important;
  top: 774px !important;
  width: 24px !important;
}

.playlists .vuesax-bold-edit {
  height: 24px !important;
  left: 1185px !important;
  position: absolute !important;
  top: 114px !important;
  width: 24px !important;
}

.playlists .vuesax-bold-edit-2-10 {
  height: 24px !important;
  left: 1185px !important;
  position: absolute !important;
  top: 224px !important;
  width: 24px !important;
}

.playlists .vuesax-bold-edit-2-8 {
  height: 24px !important;
  left: 1185px !important;
  position: absolute !important;
  top: 334px !important;
  width: 24px !important;
}

.playlists .vuesax-bold-edit-2-11 {
  height: 24px !important;
  left: 1185px !important;
  position: absolute !important;
  top: 444px !important;
  width: 24px !important;
}

.playlists .vuesax-bold-edit-2-13 {
  height: 24px !important;
  left: 1185px !important;
  position: absolute !important;
  top: 554px !important;
  width: 24px !important;
}

.playlists .vuesax-bold-edit-2-10-instance {
  height: 24px !important;
  left: 1185px !important;
  position: absolute !important;
  top: 664px !important;
  width: 24px !important;
}

.playlists .vuesax-bold-edit-2-8-instance {
  height: 24px !important;
  left: 1185px !important;
  position: absolute !important;
  top: 774px !important;
  width: 24px !important;
}

.playlists .vuesax-bold-tick {
  height: 24px !important;
  left: 23px !important;
  position: absolute !important;
  top: 224px !important;
  width: 24px !important;
}

.playlists .vuesax-bold-tick-square-1 {
  height: 24px !important;
  left: 23px !important;
  position: absolute !important;
  top: 554px !important;
  width: 24px !important;
}

.playlists .overlap-11 {
  background-color: #d9d9d9;
  height: 61px;
  left: 33px;
  position: absolute;
  top: 37px;
  width: 66px;
}

.playlists .vuesax-bold-gallery-13 {
  height: 24px !important;
  left: 21px !important;
  position: absolute !important;
  top: 19px !important;
  width: 24px !important;
}
