.episodios {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.episodios .div-8 {
  background-color: #ffffff;
  height: 1024px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.episodios .rectangle-7 {
  background-color: #d9d9d9;
  border-radius: 10px;
  height: 52px;
  left: 1336px;
  position: absolute;
  top: 42px;
  width: 52px;
}

.episodios .group-21 {
  height: 72px;
  left: 156px;
  position: absolute;
  top: 32px;
  width: 224px;
}

.episodios .group-22 {
  height: 72px;
  position: relative;
  width: 228px;
}

.episodios .text-wrapper-140 {
  color: #3e435d;
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.episodios .text-wrapper-141 {
  color: #ada7a7;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 48px;
}

.episodios .group-23 {
  height: 52px;
  left: 964px;
  position: absolute;
  top: 42px;
  width: 356px;
}

.episodios .rectangle-8 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #eaeaea;
  border-radius: 8px;
  height: 52px;
  left: 0;
  position: absolute;
  top: 0;
  width: 354px;
}

.episodios .vuesax-linear-search-normal-1-instance {
  height: 24px !important;
  left: 20px !important;
  position: absolute !important;
  top: 14px !important;
  width: 24px !important;
}

.episodios .text-wrapper-142 {
  color: #ada7a7;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 60px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 14px;
}

.episodios .vector-20 {
  height: 1024px;
  left: 116px;
  position: absolute;
  top: 0;
  width: 1px;
}

.episodios .group-24 {
  height: 784px;
  left: 32px;
  position: absolute;
  top: 208px;
  width: 54px;
}

.episodios .group-25 {
  display: block;
  height: 52px;
  left: 0;
  position: absolute;
  top: 732px;
  width: 52px;
}

.episodios .rectangle-9 {
  background-color: #18a0fb;
  border-radius: 8px;
  height: 52px;
  left: 0;
  position: absolute;
  top: 0;
  width: 52px;
}

.episodios .text-wrapper-143 {
  color: #000000;
  font-family: "DM Sans", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 8px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.3;
  position: absolute;
  top: 0;
}

.episodios .vuesax-bold-element-3-2 {
  height: 24px !important;
  left: 14px !important;
  position: absolute !important;
  top: 38px !important;
  width: 24px !important;
}

.episodios .vuesax-bold-discover-1 {
  height: 24px !important;
  left: 14px !important;
  position: absolute !important;
  top: 94px !important;
  width: 24px !important;
}

.episodios .group-26 {
  height: 52px;
  left: 1210px;
  position: absolute;
  top: 136px;
  width: 154px;
}

.episodios .rectangle-10 {
  background-color: #18a0fb;
  border-radius: 8px;
  height: 52px;
  left: 0;
  opacity: 0.1;
  position: absolute;
  top: 0;
  width: 152px;
}

.episodios .text-wrapper-144 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 20px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 14px;
}

.episodios .group-27 {
  height: 52px;
  left: 999px;
  position: absolute;
  top: 136px;
  width: 196px;
}

.episodios .rectangle-11 {
  background-color: #ff0000;
  border-radius: 8px;
  height: 52px;
  left: 0;
  opacity: 0.1;
  position: absolute;
  top: 0;
  width: 194px;
}

.episodios .text-wrapper-145 {
  color: #fb1818;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 35px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 14px;
}

.episodios .rectangle-12 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #eaeaea;
  border-radius: 8px;
  height: 835px;
  left: 156px;
  position: absolute;
  top: 220px;
  width: 1244px;
}

.episodios .vuesax-linear-minus-wrapper {
  height: 24px;
  left: 180px;
  opacity: 0.5;
  position: absolute;
  top: 244px;
  width: 24px;
}

.episodios .vuesax-linear-minus-square-2 {
  height: 24px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 24px !important;
}

.episodios .vuesax-linear-minus-square-2-wrapper {
  height: 24px;
  left: 180px;
  opacity: 0.5;
  position: absolute;
  top: 335px;
  width: 24px;
}

.episodios .vuesax-linear-minus-square-2-instance-wrapper {
  height: 24px;
  left: 180px;
  opacity: 0.5;
  position: absolute;
  top: 555px;
  width: 24px;
}

.episodios .vuesax-linear-minus-11 {
  height: 24px;
  left: 180px;
  opacity: 0.5;
  position: absolute;
  top: 665px;
  width: 24px;
}

.episodios .vuesax-linear-minus-12 {
  height: 24px;
  left: 180px;
  opacity: 0.5;
  position: absolute;
  top: 885px;
  width: 24px;
}

.episodios .vuesax-linear-minus-13 {
  height: 24px;
  left: 180px;
  opacity: 0.5;
  position: absolute;
  top: 995px;
  width: 24px;
}

.episodios .text-wrapper-146 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 228px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 243px;
}

.episodios .text-wrapper-147 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 408px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 243px;
}

.episodios .text-wrapper-148 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 896px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 334px;
}

.episodios .text-wrapper-149 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 896px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 444px;
}

.episodios .text-wrapper-150 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 896px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 554px;
}

.episodios .text-wrapper-151 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 896px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 664px;
}

.episodios .text-wrapper-152 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 896px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 774px;
}

.episodios .text-wrapper-153 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 896px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 884px;
}

.episodios .text-wrapper-154 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 896px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 994px;
}

.episodios .text-wrapper-155 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 1116px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 334px;
}

.episodios .text-wrapper-156 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 1116px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 444px;
}

.episodios .text-wrapper-157 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 1116px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 554px;
}

.episodios .text-wrapper-158 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 1116px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 664px;
}

.episodios .text-wrapper-159 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 1116px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 774px;
}

.episodios .text-wrapper-160 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 1116px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 884px;
}

.episodios .text-wrapper-161 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 1116px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 994px;
}

.episodios .text-wrapper-162 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 727px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 243px;
}

.episodios .text-wrapper-163 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 1116px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 243px;
}

.episodios .vector-21 {
  height: 1px;
  left: 180px;
  object-fit: cover;
  position: absolute;
  top: 292px;
  width: 1196px;
}

.episodios .vector-22 {
  height: 1px;
  left: 180px;
  object-fit: cover;
  position: absolute;
  top: 402px;
  width: 1196px;
}

.episodios .vector-23 {
  height: 1px;
  left: 180px;
  object-fit: cover;
  position: absolute;
  top: 512px;
  width: 1196px;
}

.episodios .vector-24 {
  height: 1px;
  left: 180px;
  object-fit: cover;
  position: absolute;
  top: 622px;
  width: 1196px;
}

.episodios .vector-25 {
  height: 1px;
  left: 180px;
  object-fit: cover;
  position: absolute;
  top: 732px;
  width: 1196px;
}

.episodios .vector-26 {
  height: 1px;
  left: 180px;
  object-fit: cover;
  position: absolute;
  top: 842px;
  width: 1196px;
}

.episodios .vector-27 {
  height: 1px;
  left: 180px;
  object-fit: cover;
  position: absolute;
  top: 952px;
  width: 1196px;
}

.episodios .rectangle-13 {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 70px;
  left: 228px;
  position: absolute;
  top: 312px;
  width: 120px;
}

.episodios .rectangle-14 {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 70px;
  left: 228px;
  position: absolute;
  top: 422px;
  width: 120px;
}

.episodios .rectangle-15 {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 70px;
  left: 228px;
  position: absolute;
  top: 532px;
  width: 120px;
}

.episodios .rectangle-16 {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 70px;
  left: 228px;
  position: absolute;
  top: 642px;
  width: 120px;
}

.episodios .rectangle-17 {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 70px;
  left: 228px;
  position: absolute;
  top: 752px;
  width: 120px;
}

.episodios .rectangle-18 {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 70px;
  left: 228px;
  position: absolute;
  top: 862px;
  width: 120px;
}
.file-input {
  display: none;
}

.episodios .rectangle-19 {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 70px;
  left: 228px;
  position: absolute;
  top: 972px;
  width: 120px;
}

.episodios .vuesax-bold-logout-wrapper {
  background-color: #18a0fb;
  border-radius: 8px;
  display: block;
  height: 52px;
  left: 0;
  position: absolute;
  top: 732px;
  width: 52px;
}

.episodios .vuesax-bold-link-13 {
  height: 24px !important;
  left: 1294px !important;
  position: absolute !important;
  top: 335px !important;
  width: 24px !important;
}
.episodios .vuesax-bold-link-13-2 {

  height: 24px !important;
  left: 1230px !important;
  position: absolute !important;
  top: 445px !important;
  width: 24px !important;

}

.episodios .vuesax-bold-link-14 {
  height: 24px !important;
  left: 1294px !important;
  position: absolute !important;
  top: 445px !important;
  width: 24px !important;
}
.episodios .vuesax-bold-link-14-2 {
  height: 24px !important;
  left: 1230px !important;
  position: absolute !important;
  top: 445px !important;
  width: 24px !important;
}

.episodios .vuesax-bold-link-15 {
  height: 24px !important;
  left: 1294px !important;
  position: absolute !important;
  top: 555px !important;
  width: 24px !important;
}

.episodios .vuesax-bold-link-15-2 {
  height: 24px !important;
  left: 1230px !important;
  position: absolute !important;
  top: 555px !important;
  width: 24px !important;
}

.episodios .vuesax-bold-link-16 {
  height: 24px !important;
  left: 1294px !important;
  position: absolute !important;
  top: 665px !important;
  width: 24px !important;
}

.episodios .vuesax-bold-link-16-2 {
  height: 24px !important;
  left: 1230px !important;
  position: absolute !important;
  top: 665px !important;
  width: 24px !important;
}


.episodios .vuesax-bold-link-17 {
  height: 24px !important;
  left: 1294px !important;
  position: absolute !important;
  top: 775px !important;
  width: 24px !important;
}

.episodios .vuesax-bold-link-17-2 {
  height: 24px !important;
  left: 1230px !important;
  position: absolute !important;
  top: 775px !important;
  width: 24px !important;
}

.episodios .vuesax-bold-link-18 {
  height: 24px !important;
  left: 1294px !important;
  position: absolute !important;
  top: 885px !important;
  width: 24px !important;
}
.episodios .vuesax-bold-link-18-2 {
  height: 24px !important;
  left: 1230px !important;
  position: absolute !important;
  top: 885px !important;
  width: 24px !important;
}

.episodios .vuesax-bold-link-19 {
  height: 24px !important;
  left: 1294px !important;
  position: absolute !important;
  top: 995px !important;
  width: 24px !important;
}

.episodios .vuesax-bold-link-19-2 {
  height: 24px !important;
  left: 1230px !important;
  position: absolute !important;
  top: 995px !important;
  width: 24px !important;
}

.episodios .vuesax-bold-tick-3 {
  height: 24px !important;
  left: 180px !important;
  position: absolute !important;
  top: 445px !important;
  width: 24px !important;
}

.episodios .vuesax-bold-tick-4 {
  height: 24px !important;
  left: 180px !important;
  position: absolute !important;
  top: 775px !important;
  width: 24px !important;
}

.episodios .rectangle-20 {
  background-color: #d9d9d9;
  height: 61px;
  left: 33px;
  position: absolute;
  top: 37px;
  width: 66px;
}

.episodios .vuesax-bold-gallery-12-instance {
  height: 24px !important;
  left: 54px !important;
  position: absolute !important;
  top: 56px !important;
  width: 24px !important;
}

.episodios .vuesax-bold-gallery-instance {
  height: 24px !important;
  left: 1350px !important;
  position: absolute !important;
  top: 56px !important;
  width: 24px !important;
}

.episodios .vuesax-bold-gallery-2 {
  height: 24px !important;
  left: 276px !important;
  position: absolute !important;
  top: 335px !important;
  width: 24px !important;
}

.episodios .vuesax-bold-gallery-3 {
  height: 24px !important;
  left: 276px !important;
  position: absolute !important;
  top: 445px !important;
  width: 24px !important;
}

.episodios .vuesax-bold-gallery-4 {
  height: 24px !important;
  left: 276px !important;
  position: absolute !important;
  top: 555px !important;
  width: 24px !important;
}

.episodios .vuesax-bold-gallery-5 {
  height: 24px !important;
  left: 276px !important;
  position: absolute !important;
  top: 665px !important;
  width: 24px !important;
}

.episodios .vuesax-bold-gallery-6 {
  height: 24px !important;
  left: 276px !important;
  position: absolute !important;
  top: 775px !important;
  width: 24px !important;
}

.episodios .vuesax-bold-gallery-7 {
  height: 24px !important;
  left: 276px !important;
  position: absolute !important;
  top: 885px !important;
  width: 24px !important;
}

.episodios .vuesax-bold-gallery-8 {
  height: 24px !important;
  left: 276px !important;
  position: absolute !important;
  top: 995px !important;
  width: 24px !important;
}

.episodios .text-wrapper-164 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 156px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 147px;
}

.episodios .text-wrapper-165 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 408px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 334px;
}

.episodios .text-wrapper-166 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 408px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 444px;
}

.episodios .text-wrapper-167 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 408px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 554px;
}

.episodios .text-wrapper-168 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 408px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 664px;
}

.episodios .text-wrapper-169 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 408px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 774px;
}

.episodios .text-wrapper-170 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 408px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 884px;
}

.episodios .text-wrapper-171 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 408px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 994px;
}

.episodios .text-wrapper-172 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 727px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 334px;
}

.episodios .text-wrapper-173 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 727px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 444px;
}

.episodios .text-wrapper-174 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 727px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 554px;
}

.episodios .text-wrapper-175 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 727px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 664px;
}

.episodios .text-wrapper-176 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 727px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 774px;
}

.episodios .text-wrapper-177 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 727px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 884px;
}

.episodios .text-wrapper-178 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 727px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 994px;
}

.episodios .text-wrapper-179 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 896px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 243px;
}

.episodios .text-wrapper-definitive {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 896px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 243px;
}
.popup-content {
  background-color: transparent !important; /* Torna o fundo transparente */
  box-shadow: none !important; /* Remove qualquer sombra */
  border: none !important; /* Remove qualquer borda */
}

