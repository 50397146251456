.podcast {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.podcast .div-6 {
  background-color: #ffffff;
  height: 1024px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.podcast .overlap-18 {
  background-color: #d9d9d9;
  border-radius: 10px;
  height: 52px;
  left: 1336px;
  position: absolute;
  top: 42px;
  width: 52px;
}

.podcast .icon-instance-node-2 {
  height: 24px !important;
  left: 14px !important;
  position: absolute !important;
  top: 14px !important;
  width: 24px !important;
}

.podcast .group-13 {
  height: 72px;
  left: 156px;
  position: absolute;
  top: 32px;
  width: 224px;
}

.podcast .group-14 {
  height: 72px;
  position: relative;
  width: 228px;
}

.podcast .text-wrapper-100 {
  color: #3e435d;
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.podcast .text-wrapper-101 {
  color: #ada7a7;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 48px;
}

.podcast .group-15 {
  height: 52px;
  left: 964px;
  position: absolute;
  top: 42px;
  width: 356px;
}

.podcast .overlap-group-8 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #eaeaea;
  border-radius: 8px;
  height: 52px;
  position: relative;
  width: 354px;
}

.podcast .vuesax-linear-search-normal-1 {
  height: 24px !important;
  left: 19px !important;
  position: absolute !important;
  top: 13px !important;
  width: 24px !important;
}

.podcast .text-wrapper-102 {
  color: #ada7a7;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 59px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 13px;
}

.podcast .vector-12 {
  height: 1024px;
  left: 116px;
  position: absolute;
  top: 0;
  width: 1px;
}

.podcast .group-16 {
  height: 784px;
  left: 32px;
  position: absolute;
  top: 208px;
  width: 54px;
}

.podcast .vuesax-bold-logout-2-wrapper {
  background-color: #18a0fb;
  border-radius: 8px;
  display: block;
  height: 52px;
  left: 0;
  position: absolute;
  top: 732px;
  width: 52px;
}

.podcast .text-wrapper-103 {
  color: #000000;
  font-family: "DM Sans", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 8px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.3;
  position: absolute;
  top: 0;
}

.podcast .vuesax-bold-element-3-1 {
  height: 24px !important;
  left: 14px !important;
  position: absolute !important;
  top: 38px !important;
  width: 24px !important;
}

.podcast .vuesax-bold-discover-2 {
  height: 24px !important;
  left: 14px !important;
  position: absolute !important;
  top: 94px !important;
  width: 24px !important;
}

.podcast .group-17 {
  display: block;
  height: 52px;
  left: 1184px;
  position: absolute;
  top: 136px;
  width: 214px;
}

.podcast .overlap-19 {
  border-radius: 8px;
  height: 52px;
  position: relative;
  width: 212px;
}

.podcast .rectangle-5 {
  background-color: #18a0fb;
  border-radius: 8px;
  height: 52px;
  left: 0;
  opacity: 0.1;
  position: absolute;
  top: 0;
  width: 212px;
}

.podcast .text-wrapper-104 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 20px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 14px;
}

.podcast .group-18 {
  height: 52px;
  left: 973px;
  position: absolute;
  top: 136px;
  width: 196px;
}

.podcast .overlap-20 {
  border-radius: 8px;
  height: 52px;
  position: relative;
  width: 194px;
}

.podcast .rectangle-6 {
  background-color: #ff0000;
  border-radius: 8px;
  height: 52px;
  left: 0;
  opacity: 0.1;
  position: absolute;
  top: 0;
  width: 194px;
}

.podcast .text-wrapper-105 {
  color: #fb1818;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 12px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 14px;
}

.podcast .text-wrapper-106 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 156px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 147px;
}

.podcast .overlap-21 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #eaeaea;
  border-radius: 8px;
  height: 835px;
  left: 156px;
  position: absolute;
  top: 220px;
  width: 1244px;
}

.podcast .vuesax-linear-minus-5 {
  background-image: url(../../../static/img/vuesax-linear-minus-square-2.svg) !important;
  left: 23px !important;
  position: absolute !important;
  top: 23px !important;
}

.podcast .vuesax-linear-minus-6 {
  background-image: url(../../../static/img/vuesax-linear-minus-square-2.svg) !important;
  left: 23px !important;
  position: absolute !important;
  top: 114px !important;
}

.podcast .vuesax-linear-minus-7 {
  background-image: url(../../../static/img/vuesax-linear-minus-square-2.svg) !important;
  left: 23px !important;
  position: absolute !important;
  top: 334px !important;
}

.podcast .vuesax-linear-minus-8 {
  background-image: url(../../../static/img/vuesax-linear-minus-square-2.svg) !important;
  left: 23px !important;
  position: absolute !important;
  top: 444px !important;
}

.podcast .vuesax-linear-minus-9 {
  background-image: url(../../../static/img/vuesax-linear-minus-square-2.svg) !important;
  left: 23px !important;
  position: absolute !important;
  top: 664px !important;
}

.podcast .vuesax-linear-minus-10 {
  background-image: url(../../../static/img/vuesax-linear-minus-square-2.svg) !important;
  left: 23px !important;
  position: absolute !important;
  top: 774px !important;
}

.podcast .text-wrapper-107 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 71px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 22px;
}

.podcast .text-wrapper-108 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 251px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 22px;
}

.podcast .text-wrapper-109 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 251px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 113px;
}

.podcast .text-wrapper-110 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 251px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 223px;
}

.podcast .text-wrapper-111 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 251px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 333px;
}

.podcast .text-wrapper-112 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 251px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 443px;
}

.podcast .text-wrapper-113 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 251px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 553px;
}

.podcast .text-wrapper-114 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 251px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 663px;
}

.podcast .text-wrapper-115 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 251px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 773px;
}

.podcast .text-wrapper-116 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 570px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 113px;
}

.podcast .text-wrapper-117 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 570px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 223px;
}

.podcast .text-wrapper-118 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 570px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 333px;
}

.podcast .text-wrapper-119 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 570px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 443px;
}

.podcast .text-wrapper-120 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 570px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 553px;
}

.podcast .text-wrapper-121 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 570px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 663px;
}

.podcast .text-wrapper-122 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 570px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 773px;
}

.podcast .text-wrapper-123 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 739px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 113px;
}

.podcast .text-wrapper-124 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 739px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 223px;
}

.podcast .text-wrapper-125 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 739px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 333px;
}

.podcast .text-wrapper-126 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 739px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 443px;
}

.podcast .text-wrapper-127 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 739px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 553px;
}

.podcast .text-wrapper-128 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 739px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 663px;
}

.podcast .text-wrapper-129 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 739px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 773px;
}

.podcast .text-wrapper-130 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 959px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 113px;
}

.podcast .text-wrapper-131 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 959px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 223px;
}

.podcast .text-wrapper-132 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 959px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 333px;
}

.podcast .text-wrapper-133 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 959px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 443px;
}

.podcast .text-wrapper-134 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 959px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 553px;
}

.podcast .text-wrapper-135 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 959px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 663px;
}

.podcast .text-wrapper-136 {
  color: #18a0fb;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 959px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 773px;
}

.podcast .text-wrapper-137 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 570px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 22px;
}

.podcast .text-wrapper-138 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 739px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 22px;
}

.podcast .text-wrapper-139 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 959px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  top: 22px;
}

.podcast .vector-13 {
  height: 1px;
  left: 23px;
  object-fit: cover;
  position: absolute;
  top: 70px;
  width: 1196px;
}

.podcast .vector-14 {
  height: 1px;
  left: 23px;
  object-fit: cover;
  position: absolute;
  top: 180px;
  width: 1196px;
}

.podcast .vector-15 {
  height: 1px;
  left: 23px;
  object-fit: cover;
  position: absolute;
  top: 290px;
  width: 1196px;
}

.podcast .vector-16 {
  height: 1px;
  left: 23px;
  object-fit: cover;
  position: absolute;
  top: 400px;
  width: 1196px;
}

.podcast .vector-17 {
  height: 1px;
  left: 23px;
  object-fit: cover;
  position: absolute;
  top: 510px;
  width: 1196px;
}

.podcast .vector-18 {
  height: 1px;
  left: 23px;
  object-fit: cover;
  position: absolute;
  top: 620px;
  width: 1196px;
}

.podcast .vector-19 {
  height: 1px;
  left: 23px;
  object-fit: cover;
  position: absolute;
  top: 730px;
  width: 1196px;
}

.podcast .overlap-22 {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 70px;
  left: 72px;
  position: absolute;
  top: 92px;
  width: 120px;
}

.podcast .vuesax-bold-gallery-12 {
  height: 24px !important;
  left: 48px !important;
  position: absolute !important;
  top: 23px !important;
  width: 24px !important;
}

.podcast .overlap-23 {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 70px;
  left: 72px;
  position: absolute;
  top: 202px;
  width: 120px;
}

.podcast .overlap-24 {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 70px;
  left: 72px;
  position: absolute;
  top: 312px;
  width: 120px;
}

.podcast .overlap-25 {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 70px;
  left: 72px;
  position: absolute;
  top: 422px;
  width: 120px;
}

.podcast .overlap-26 {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 70px;
  left: 72px;
  position: absolute;
  top: 532px;
  width: 120px;
}

.podcast .overlap-27 {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 70px;
  left: 72px;
  position: absolute;
  top: 642px;
  width: 120px;
}

.podcast .overlap-28 {
  background-color: #d9d9d9;
  border-radius: 6px;
  height: 70px;
  left: 72px;
  position: absolute;
  top: 752px;
  width: 120px;
}

.podcast .vuesax-bold-link-6 {
  height: 24px !important;
  left: 1137px !important;
  position: absolute !important;
  top: 114px !important;
  width: 24px !important;
}

.podcast .vuesax-bold-link-7 {
  height: 24px !important;
  left: 1137px !important;
  position: absolute !important;
  top: 224px !important;
  width: 24px !important;
}

.podcast .vuesax-bold-link-8 {
  height: 24px !important;
  left: 1137px !important;
  position: absolute !important;
  top: 334px !important;
  width: 24px !important;
}

.podcast .vuesax-bold-link-9 {
  height: 24px !important;
  left: 1137px !important;
  position: absolute !important;
  top: 444px !important;
  width: 24px !important;
}

.podcast .vuesax-bold-link-10 {
  height: 24px !important;
  left: 1137px !important;
  position: absolute !important;
  top: 554px !important;
  width: 24px !important;
}

.podcast .vuesax-bold-link-11 {
  height: 24px !important;
  left: 1137px !important;
  position: absolute !important;
  top: 664px !important;
  width: 24px !important;
}

.podcast .vuesax-bold-link-12 {
  height: 24px !important;
  left: 1137px !important;
  position: absolute !important;
  top: 774px !important;
  width: 24px !important;
}

.podcast .vuesax-bold-edit-2-9 {
  height: 24px !important;
  left: 1185px !important;
  position: absolute !important;
  top: 114px !important;
  width: 24px !important;
}

.podcast .vuesax-bold-edit-2-12 {
  height: 24px !important;
  left: 1185px !important;
  position: absolute !important;
  top: 224px !important;
  width: 24px !important;
}

.podcast .vuesax-bold-edit-2-9-instance {
  height: 24px !important;
  left: 1185px !important;
  position: absolute !important;
  top: 334px !important;
  width: 24px !important;
}

.podcast .vuesax-bold-edit-2-12-instance {
  height: 24px !important;
  left: 1185px !important;
  position: absolute !important;
  top: 444px !important;
  width: 24px !important;
}

.podcast .vuesax-bold-edit-3 {
  height: 24px !important;
  left: 1185px !important;
  position: absolute !important;
  top: 554px !important;
  width: 24px !important;
}

.podcast .vuesax-bold-edit-4 {
  height: 24px !important;
  left: 1185px !important;
  position: absolute !important;
  top: 664px !important;
  width: 24px !important;
}

.podcast .vuesax-bold-edit-5 {
  height: 24px !important;
  left: 1185px !important;
  position: absolute !important;
  top: 774px !important;
  width: 24px !important;
}

.podcast .vuesax-bold-tick-square-1-instance {
  height: 24px !important;
  left: 23px !important;
  position: absolute !important;
  top: 224px !important;
  width: 24px !important;
}

.podcast .vuesax-bold-tick-2 {
  height: 24px !important;
  left: 23px !important;
  position: absolute !important;
  top: 554px !important;
  width: 24px !important;
}

.podcast .overlap-29 {
  background-color: #d9d9d9;
  height: 61px;
  left: 33px;
  position: absolute;
  top: 37px;
  width: 66px;
}

.podcast .vuesax-bold-gallery-13-instance {
  height: 24px !important;
  left: 21px !important;
  position: absolute !important;
  top: 19px !important;
  width: 24px !important;
}
